import * as Actions from "../actions";

const initialState = {
  mainbackgrounds: [],
  infopoints: [],
};

const infoPointsReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.LOAD_DATABASE:
    case Actions.INSERT_INFOPOINT:
    case Actions.UPDATE_INFOPOINT:
    case Actions.DELETE_INFOPOINT: {
      return {
        ...state,
        ...action,
      };
    }
    default: {
      return state;
    }
  }
};

export default infoPointsReducer;
