import React, { Component } from "react";
import * as Actions from "./store/actions";
import reducer from "./store/reducers";
import { withRouter } from "react-router-dom";
import withReducer from "app/store/withReducer";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FusePageCarded, FuseAnimate } from "@fuse";
import { IconButton, Button, Drawer, Divider } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { withStyles } from "@material-ui/core/styles";
import * as appActions from "app/store/actions";
import {
  Table,
  Typography,
  Input,
  Spin,
  Form,
  Modal,
  Row,
  Col
} from "antd";
import {ChromePicker} from 'react-color';

const { Title } = Typography;
const { confirm } = Modal;
const FormItem = Form.Item;

const formItemLayout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 12 }
};

const defaultPagination = {
  pageSizeOptions: ["10", "20", "50", "70", "100", "200", "500"],
  showSizeChanger: true,
  size: "small",
  position: "top"
};

const styles = theme => ({
  button: {
    marginTop: "-12px"
  },
  input: {
    display: "none"
  },
  list: {
    width: 500
  }
});

class ColorFamily extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      visible: false,
      type: "new",
      id: null,
      rgb: null
    };
    props.getColorFamily();
  }

  toggleDrawer = (type, record, open) => () => {
    if (type === "new") {
      this.setState({
        name: "",
        type: type,
        id: null,
        rgb: null
      });
    } else if (type === "edit") {
      this.setState({
        id: record.id,
        name: record.name,
        type: type,
        rgb: JSON.parse(record.rgb)
      });
    }
    this.setState({
      visible: open
    });
  };

  handleSubmit = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { type, id } = this.state;
        console.log("val", values, id);
        this.setState({ visible: false });
        if (type === "new") {
          this.props.addColorFamily(values);
        }
        if (type === "edit" && id !== null) {
          this.props.updateColorFamily(values, id);
        }
      }
    });
  };

  handleDeleteEvent = record => {
    const { deleteColorFamily } = this.props;
    confirm({
      title: "Do you want to delete this Item?",
      onOk() {
        deleteColorFamily(record);
      },
      onCancel() {}
    });
  };

  moveAction = (type, record) => () => {
    const { colorFamily } = this.props;
    const index = colorFamily.findIndex(el => el.id === record.id);
    let swapIndex = -1;
    if (type === "up" && index !== 0) {
      swapIndex = index - 1;
    } else if (type === "down" && index !== colorFamily.length - 1) {
      swapIndex = index + 1;
    }
    if (swapIndex !== -1) {
      this.props.setSortOrder({
        id: colorFamily[index].id,
        sort_order: colorFamily[swapIndex].sort_order
      });
      this.props.setSortOrder({
        id: colorFamily[swapIndex].id,
        sort_order: colorFamily[index].sort_order
      });
    }
  };

  render() {
    const { classes, colorFamily, isLoading } = this.props;
    const { name, visible, type, rgb } = this.state;
    const { getFieldDecorator, setFieldsValue } = this.props.form;

    const columns = [
      {
        key: "name",
        title: "Name",
        dataIndex: "name",
        width: 400
      },
      {
        key: "rgb",
        title: "RGB Color",
        dataIndex: "rgb",
        width: 100,
        render: (text, record) => (
          <Row className="list__thumbnail">
            <div style={{background: record.rgb ? `rgb(${JSON.parse(record.rgb).r}, ${JSON.parse(record.rgb).g}, ${JSON.parse(record.rgb).b}, ${JSON.parse(record.rgb).a})`: 'transparent', width: '100%', height: 30}} />
          </Row>
        )
      },
      {
        key: "action",
        title: "Action",
        dataIndex: "id",
        width: 250,
        render: (text, record) => (
          <Row>
            <IconButton
              className={classes.button}
              aria-label="ArrowUp"
              onClick={this.moveAction("up", record)}
            >
              <ArrowUpwardIcon />
            </IconButton>
            <IconButton
              className={classes.button}
              aria-label="ArrowDown"
              onClick={this.moveAction("down", record)}
            >
              <ArrowDownwardIcon />
            </IconButton>
            <IconButton
              className={classes.button}
              aria-label="Delete"
              onClick={this.toggleDrawer("edit", record, true)}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              className={classes.button}
              aria-label="Edit"
              onClick={e => {
                e.preventDefault();
                this.handleDeleteEvent(record);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Row>
        )
      }
    ];

    return (
      <Spin tip="Loading..." spinning={isLoading}>
        <FusePageCarded
          classes={{
            toolbar: "p-0",
            header: "min-h-72 h-72 sm:h-136 sm:min-h-136"
          }}
          header={
            <div className="flex flex-1 w-full items-center justify-between">
              <div className="flex flex-col items-start max-w-full">
                <div className="flex items-center max-w-full">
                  <div className="flex flex-col min-w-0">
                    <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                      <Title
                        level={4}
                        className="text-16 sm:text-20 truncate"
                      />
                    </FuseAnimate>
                  </div>
                </div>
              </div>
              <FuseAnimate animation="transition.slideRightIn" delay={300}>
                <Button
                  className="whitespace-no-wrap"
                  variant="contained"
                  onClick={this.toggleDrawer("new", null, true)}
                >
                  Add
                </Button>
              </FuseAnimate>
            </div>
          }
          contentToolbar={
            <Title
              level={4}
              style={{ marginLeft: "25px" }}
              className="text-16 sm:text-20 truncate"
              classes={{ root: "w-full h-64" }}
            >
              {"Color Family"}
            </Title>
          }
          content={
            <div className="p-24">
              <Row>
                <Drawer
                  anchor="right"
                  open={visible}
                  variant="temporary"
                  onClose={this.toggleDrawer(null, null, false)}
                  classes={{ paper: classes.list }}
                  onRendered={() => {
                    var data = {
                      name: this.state.name
                    };
                    setFieldsValue({ ...data });
                  }}
                >
                  <div tabIndex={0} role="button">
                    <Title
                      level={3}
                      className="pt-16"
                      style={{ textAlign: "center" }}
                    >
                      {type === "new" ? "Add" : "Edit"} Color Family
                    </Title>
                    <Divider />
                    <Form layout="vertical" style={{ marginTop: "20px" }}>
                      <Row gutter={8}>
                        <Col span={2} />
                        <Col span={22}>
                          <FormItem key="name" {...formItemLayout} label="Name">
                            {getFieldDecorator("name", {
                              initialValue: name,
                              rules: [
                                {
                                  required: true,
                                  message: "Enter Name."
                                }
                              ]
                            })(
                              <Input
                                placeholder="Enter Color Family Name"
                                onChange={e => {
                                  this.setState({ name: e.target.value });
                                }}
                              />
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col span={2} />
                        <Col span={22}>
                          <FormItem key="rgb" {...formItemLayout} label="RGB Color">
                            {getFieldDecorator("rgb", {
                              initialValue: rgb,
                              rules: [
                                {
                                  required: true,
                                  message: "Enter RGB Color."
                                }
                              ]
                            })(
                              <ChromePicker
                                color={rgb || false}
                                value={rgb}
                                width={'100%'}
                                defaultView={'rgb'}
                                styles={{
                                  'default': {
                                    picker:{
                                      background: 'transparent',
                                      boxShadow: 'none',
                                      borderRadius: 0
                                    },
                                    saturation:{
                                      borderRadius: '8px',
                                      paddingBottom: '240px',
                                      background: 'transparent'
                                    },
                                    Saturation:{
                                      radius: '12px 12px 0 0'
                                    },
                                    body:{
                                      background: 'transparent',
                                      padding: 0,
                                      paddingTop: 15
                                    },
                                    color: {
                                      width: 0
                                    },
                                    swatch:{
                                      display: 'none'
                                    },
                                    hue: {
                                      height: 20
                                    },
                                    alpha: {
                                      height: 20
                                    },
                                  }
                                }}
                                onChangeComplete={ color => {
                                  this.setState({ rgb: color.rgb });
                                }}
                                onChange={ color => {
                                  this.setState({ rgb: color.rgb });
                                }}
                              />
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                    </Form>
                    <div
                      style={{
                        position: "absolute",
                        left: 0,
                        bottom: 0,
                        width: "100%",
                        borderTop: "1px solid #e9e9e9",
                        padding: "10px 16px",
                        background: "#fff",
                        textAlign: "right"
                      }}
                    >
                      <Button
                        onClick={this.toggleDrawer(null, null, false)}
                        style={{ marginRight: 8 }}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={this.handleSubmit}
                        variant="contained"
                        color="primary"
                        // disabled={!this.canBeSubmitted()}
                      >
                        {type === "new" ? "ADD" : "UPDATE"}
                      </Button>
                    </div>
                  </div>
                </Drawer>
                <Table
                  bordered
                  rowKey="id"
                  className="mtable"
                  pagination={defaultPagination}
                  columns={columns}
                  dataSource={colorFamily}
                  scroll={{ x: 750, y: 500 }}
                />
              </Row>
            </div>
          }
        />
      </Spin>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getColorFamily: Actions.getColorFamily,
      addColorFamily: Actions.addColorFamily,
      deleteColorFamily: Actions.deleteColorFamily,
      updateColorFamily: Actions.updateColorFamily,
      showMessage: appActions.showMessage,
      setSortOrder: Actions.setSortOrder
    },
    dispatch
  );
}

function mapStateToProps({ colorFamily, fuse }) {
  return {
    colorFamily: colorFamily.colorFamily.colorFamily,
    isLoading: fuse.loading.isLoading
  };
}
const ColorFamilyComponent = Form.create()(ColorFamily);

export default withReducer("colorFamily", reducer)(
  withStyles(styles, { withTheme: true })(
    withRouter(
      connect(
        mapStateToProps,
        mapDispatchToProps
      )(ColorFamilyComponent)
    )
  )
);
