import axios from "axios";
import { showMessage } from "app/store/actions/fuse";
import { API_SERVER_URL } from "app/main/config";
import { uploadLoading } from "app/store/actions/fuse";

export const GET_COLOR_FAMILY = "[COLOR FAMILY] GET COLOR FAMILY";
export function getColorFamily() {
  return dispatch => {
    axios
      .post(`${API_SERVER_URL}/api/colorfamily/read/colorfamily`)
      .then(response => {
        const { colorFamily } = response.data;
        dispatch({
          type: GET_COLOR_FAMILY,
          colorFamily: colorFamily
        });
      })
      .catch(err => {
        dispatch(
          showMessage({
            message: "Failed to load COLOR FAMILY data",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right"
            },
            variant: "error"
          })
        );
      });
  };
}

export const ADD_COLOR_FAMILY = "[COLOR FAMILY] ADD COLOR FAMILY";
export function addColorFamily(data) {
  var newData = {
    name: data.name,
    rgb: JSON.stringify(data.rgb.rgb)
  };
  return dispatch => {
    axios
      .post(`${API_SERVER_URL}/api/colorfamily/insert/colorfamily`, {
        ...newData
      })
      .then(res => {
        const { success } = res.data;
        if (success === true) {
          dispatch(
            showMessage({
              message: "Successfully Inserted",
              autoHideDuration: 2000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "right"
              },
              variant: "success"
            })
          );
          Promise.all([
            dispatch({
              type: ADD_COLOR_FAMILY
            })
          ]).then(() => dispatch(getColorFamily()));
        }
      })
      .catch(err => {
        dispatch(
          showMessage({
            message: "Failed to upload data",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right"
            },
            variant: "error"
          })
        );

        console.log("err", err);
      });
  };
}

export const DELETE_COLOR_FAMILY = "[COLOR FAMILY] DELETE COLOR FAMILY";
export function deleteColorFamily(record) {
  return dispatch => {
    axios
      .post(`${API_SERVER_URL}/api/colorfamily/remove/colorfamily`, {
        id: record.id
      })
      .then(res => {
        const { success } = res.data;
        if (success === true) {
          dispatch(
            showMessage({
              message: "Successfully Deleted",
              autoHideDuration: 2000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "right"
              },
              variant: "success"
            })
          );
          Promise.all([
            dispatch({
              type: DELETE_COLOR_FAMILY
            })
          ]).then(() => dispatch(getColorFamily()));
        }
      })
      .catch(err => {
        dispatch(
          showMessage({
            message: "Failed to upload data",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right"
            },
            variant: "error"
          })
        );

        console.log("err", err);
      });
  };
}

export const UPDATE_COLOR_FAMILY = "[COLOR FAMILY] UPDATE COLOR FAMILY";
export function updateColorFamily(data, id) {
  var updateData = {
    id: id,
    name: data.name,
    rgb: JSON.stringify(data.rgb.rgb)
  };

  return dispatch => {
    axios
      .post(`${API_SERVER_URL}/api/colorfamily/update/colorfamily`, {
        ...updateData
      })
      .then(res => {
        const { success } = res.data;
        if (success === true) {
          dispatch(
            showMessage({
              message: "Successfully Updated",
              autoHideDuration: 2000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "right"
              },
              variant: "success"
            })
          );
          Promise.all([
            dispatch({
              type: UPDATE_COLOR_FAMILY
            })
          ]).then(() => dispatch(getColorFamily()));
        }
      })
      .catch(err => {
        dispatch(
          showMessage({
            message: "Failed to upload data",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right"
            },
            variant: "error"
          })
        );

        console.log("err", err);
      });
  };
}

export const SET_COLOR_FAMILY_SORT_ORDER = "[COLOR FAMILY] SET SORT ORDER";
export function setSortOrder(data) {
  return dispatch => {
    dispatch(uploadLoading(true));
    axios
      .post(`${API_SERVER_URL}/api/base/setSortOrder`, {
        id: data.id,
        sort_order: data.sort_order,
        table_name: "color_family"
      })
      .then(response => {
        const { success } = response.data;
        if (success === true) {
          dispatch(uploadLoading(false));
          Promise.all([
            dispatch({
              type: SET_COLOR_FAMILY_SORT_ORDER
            })
          ]).then(() => dispatch(getColorFamily()));
        } else {
          dispatch(uploadLoading(false));
        }
      })
      .catch(err => {
        dispatch(uploadLoading(false));
        dispatch(
          showMessage({
            message: "Failed to set sort order",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right"
            },
            variant: "error"
          })
        );
        console.log("failed to set sort order in shape style.", err);
      });
  };
}
