import ColorFamily from "./ColorFamily";
import { authRoles } from "app/auth";

export const ColorFamilyConfig = {
  settings: {
    layout: {}
  },
  auth: authRoles.designer,
  routes: [
    {
      path: "/colorfamily",
      component: ColorFamily
    }
  ]
};
