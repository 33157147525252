import axios from 'axios'
import { showMessage } from 'app/store/actions/fuse'
import {
  API_SERVER_URL,
  jsonArray,
  uploadFiles,
  putFiles,
  deleteFiles,
} from 'app/main/config'
import { uploadLoading } from 'app/store/actions/fuse'

export const GET_COLOR = '[COLOR] GET COLOR'
export function getColor() {
  return (dispatch) => {
    axios
      .post(`${API_SERVER_URL}/api/color/read/color`)
      .then((response) => {
        const { color } = response.data
        dispatch({
          type: GET_COLOR,
          color: color,
        })
      })
      .catch((err) => {
        dispatch(
          showMessage({
            message: 'Failed to load COLOR data',
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'error',
          }),
        )
      })
  }
}

export const GET_COLOR_FAMILY = '[COLOR] GET COLOR FAMILY'
export function getColorFamily() {
  return (dispatch) => {
    axios
      .post(`${API_SERVER_URL}/api/colorfamily/read/colorfamily`)
      .then((response) => {
        const { colorFamily, success } = response.data
        if (success === true) {
          dispatch({
            type: GET_COLOR_FAMILY,
            colorFamily: colorFamily,
          })
        } else {
          Promise.all([
            dispatch({
              type: GET_COLOR,
            }),
          ]).then(() => dispatch(getColor()))
        }
      })
  }
}

export const ADD_COLOR = '[COLOR] ADD COLOR'
export function addColor(data) {
  var newData = {
    color_family_id: data.color_family_id,
    name: data.name,
    thumbnail: data.thumbnail[0].name,
    rgb: JSON.stringify(data.rgb.rgb),
  }
  const formData = new FormData()
  formData.append('file', data.thumbnail[0].originFileObj)

  return (dispatch) => {
    axios
      .post(`${API_SERVER_URL}/api/color/insert/color`, {
        ...newData,
      })
      .then((res) => {
        dispatch(uploadLoading(true))
        if (process.env.REACT_APP_MODE === 'production') {
          uploadFiles(
            data.thumbnail.filter((file) => file.originFileObj !== undefined),
            `uploads/color/${res.data.color.guid + '_' + res.data.color.id}/`,
          )
            .then((thumbnail) => {
              const uploadData = [
                {
                  fieldName: 'thumbnail',
                  type: 'single',
                  data: thumbnail,
                },
              ]
              axios
                .post(`${API_SERVER_URL}/api/base/updateUploadData`, {
                  id: res.data.color.id,
                  tableName: 'color',
                  data: uploadData,
                })
                .then((response) => {
                  const { success } = response.data
                  dispatch(uploadLoading(false))
                  if (success === true) {
                    dispatch(
                      showMessage({
                        message: 'Successfully Inserted',
                        autoHideDuration: 2000,
                        anchorOrigin: {
                          vertical: 'top',
                          horizontal: 'right',
                        },
                        variant: 'success',
                      }),
                    )
                    Promise.all([
                      dispatch({
                        type: ADD_COLOR,
                      }),
                    ]).then(() => dispatch(getColor()))
                  } else {
                    console.log(success)
                  }
                })
            })
            .catch((err) => {
              console.log('failed to upload shap thumbnail. ', err)
              return
            })
        } else {
          formData.append('guid', res.data.color.guid)
          formData.append('id', res.data.color.id)
          axios
            .post(`${API_SERVER_URL}/api/color/upload`, formData)
            .then((response) => {
              const { success } = response.data
              dispatch(uploadLoading(false))
              if (success === true) {
                axios.post(`${API_SERVER_URL}/uploads/clear`).then((res) => {
                  if (res.data.success === true) {
                    dispatch(
                      showMessage({
                        message: 'Successfully Inserted',
                        autoHideDuration: 2000,
                        anchorOrigin: {
                          vertical: 'top',
                          horizontal: 'right',
                        },
                        variant: 'success',
                      }),
                    )
                    Promise.all([
                      dispatch({
                        type: ADD_COLOR,
                      }),
                    ]).then(() => dispatch(getColor()))
                  }
                })
              }
            })
            .catch((err) => {
              dispatch(
                showMessage({
                  message: 'Failed to add COLOR data',
                  autoHideDuration: 2000,
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                  },
                  variant: 'error',
                }),
              )

              console.log('err', err)
            })
        }
      })
      .catch((err) => {
        dispatch(
          showMessage({
            message: 'Failed to upload data',
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'error',
          }),
        )

        console.log('err', err)
      })
  }
}

export const DELETE_COLOR = '[COLOR] DELETE COLOR'
export function deleteColor(record) {
  return (dispatch) => {
    axios
      .post(`${API_SERVER_URL}/api/color/remove/color`, {
        id: record.id,
      })
      .then((response) => {
        const { success } = response.data
        if (success === true) {
          var p1 = new Promise((resolve, reject) => {
            dispatch(uploadLoading(true))
            if (process.env.REACT_APP_MODE === 'production') {
              const data = []
              data.push(record.thumbnail)
              deleteFiles(data)
                .then((res) => {
                  resolve('success')
                })
                .catch((err) => {
                  console.log(err)
                  reject(err)
                })
            } else {
              resolve('success')
            }
          })
          p1.then((p1Value) => {
            dispatch(uploadLoading(false))
            dispatch(
              showMessage({
                message: 'Successfully Deleted',
                autoHideDuration: 2000,
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
                },
                variant: 'success',
              }),
            )

            Promise.all([
              dispatch({
                type: DELETE_COLOR,
              }),
            ]).then(() => dispatch(getColor()))
          }).catch((err) => {
            dispatch(uploadLoading(false))
            dispatch(
              showMessage({
                message: 'AWS S3 DELETE COLOR ERROR',
                autoHideDuration: 4000,
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
                },
                variant: 'error',
              }),
            )
            return
          })
        } else {
          dispatch(
            showMessage({
              message: 'Some Door Colors are using this style',
              autoHideDuration: 4000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'warning',
            }),
          )
        }
      })
      .catch((err) => {
        dispatch(uploadLoading(false))
        dispatch(
          showMessage({
            message: 'Failed to delete COLOR data',
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'error',
          }),
        )
        console.log('err', err)
      })
  }
}

export const UPDATE_COLOR = '[COLOR] UPDATE COLOR'
export function updateColor(data, id, guid) {
  var updateData = {
    id: id,
    color_family_id: data.color_family_id,
    name: data.name,
    thumbnail: jsonArray([data.thumbnail[0]]),
    rgb: JSON.stringify(data.rgb),
  }

  return (dispatch) => {
    axios
      .post(`${API_SERVER_URL}/api/color/update/color`, {
        ...updateData,
      })
      .then((res) => {
        dispatch(uploadLoading(true))
        if (process.env.REACT_APP_MODE === 'production') {
          putFiles(
            data.thumbnail.filter((file) => file.originFileObj !== undefined),
            `uploads/color/${guid + '_' + id}/`,
          )
            .then((thumbnail) => {
              const uploadData = [
                {
                  fieldName: 'thumbnail',
                  type: 'single',
                  data: thumbnail,
                },
              ]

              axios
                .post(`${API_SERVER_URL}/api/base/updateUploadData`, {
                  id: id,
                  tableName: 'color',
                  data: uploadData,
                })
                .then((response) => {
                  const { success } = response.data
                  dispatch(uploadLoading(false))
                  if (success === true) {
                    dispatch(
                      showMessage({
                        message: 'Successfully Updated',
                        autoHideDuration: 2000,
                        anchorOrigin: {
                          vertical: 'top',
                          horizontal: 'right',
                        },
                        variant: 'success',
                      }),
                    )
                    Promise.all([
                      dispatch({
                        type: UPDATE_COLOR,
                      }),
                    ]).then(() => dispatch(getColor()))
                  } else {
                    console.log(success)
                  }
                })
            })
            .catch((err) => {
              console.log('failed to put shape style thumbnail. ', err)
              return
            })
        } else {
          if (data.thumbnail[0].originFileObj !== undefined) {
            const formData = new FormData()
            formData.append('file', data.thumbnail[0].originFileObj)
            formData.append('guid', guid)
            formData.append('id', id)
            axios
              .post(`${API_SERVER_URL}/api/color/upload`, formData)
              .then((response) => {
                const { success } = response.data
                dispatch(uploadLoading(false))
                if (success === true) {
                  axios.post(`${API_SERVER_URL}/uploads/clear`).then((res) => {
                    if (res.data.success === true) {
                      dispatch(
                        showMessage({
                          message: 'Successfully Updated',
                          autoHideDuration: 2000,
                          anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                          },
                          variant: 'success',
                        }),
                      )
                      Promise.all([
                        dispatch({
                          type: UPDATE_COLOR,
                        }),
                      ]).then(() => dispatch(getColor()))
                    }
                  })
                }
              })
          } else {
            dispatch(uploadLoading(false))
            dispatch(
              showMessage({
                message: 'Successfully Updated',
                autoHideDuration: 2000,
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
                },
                variant: 'success',
              }),
            )
            Promise.all([
              dispatch({
                type: UPDATE_COLOR,
              }),
            ]).then(() => dispatch(getColor()))
          }
        }
      })
      .catch((err) => {
        dispatch(
          showMessage({
            message: 'Failed to update COLOR data',
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'error',
          }),
        )

        console.log('err', err)
      })
  }
}

export const SET_COLOR_SORT_ORDER = '[COLOR] SET SORT ORDER'
export function setSortOrder(data) {
  return (dispatch) => {
    dispatch(uploadLoading(true))
    axios
      .post(`${API_SERVER_URL}/api/base/setSortOrder`, {
        id: data.id,
        sort_order: data.sort_order,
        table_name: 'color',
      })
      .then((response) => {
        const { success } = response.data
        if (success === true) {
          dispatch(uploadLoading(false))
          Promise.all([
            dispatch({
              type: SET_COLOR_SORT_ORDER,
            }),
          ]).then(() => dispatch(getColor()))
        } else {
          dispatch(uploadLoading(false))
        }
      })
      .catch((err) => {
        dispatch(uploadLoading(false))
        dispatch(
          showMessage({
            message: 'Failed to set sort order',
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'error',
          }),
        )
        console.log('failed to set sort order in shape style.', err)
      })
  }
}
