import axios from 'axios'
import { showMessage } from 'app/store/actions/fuse'
import {
  API_SERVER_URL,
  jsonArray,
  uploadFiles,
  putFiles,
  deleteFiles,
} from 'app/main/config'
import { uploadLoading } from 'app/store/actions/fuse'

export const GET_MAIN_BACKGROUND = '[MAIN_BACKGROUND] GET MAIN BACKGROUND'
export function getMainBackground() {
  return (dispatch) => {
    axios
      .post(`${API_SERVER_URL}/api/mainbackground/read/main_background`)
      .then((response) => {
        const { mainbackground } = response.data
        dispatch({
          type: GET_MAIN_BACKGROUND,
          mainbackground: mainbackground,
        })
      })
      .catch((err) => {
        dispatch(
          showMessage({
            message: 'Failed to load MAIN BACKGROUND data',
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'error',
          }),
        )
      })
  }
}

export const GET_SHAPE_CATEGORY = '[SHAPES] GET SHAPE CATEGORY'
export function getShapeCategory() {
  return (dispatch) => {
    axios.post(`${API_SERVER_URL}/api/shapes/read/shapes`).then((response) => {
      const { shapes, success } = response.data
      if (success === true) {
        dispatch({
          type: GET_SHAPE_CATEGORY,
          shapeCategory: shapes,
        })
      } else {
        Promise.all([
          dispatch({
            type: GET_MAIN_BACKGROUND,
          }),
        ]).then(() => dispatch(getMainBackground()))
      }
    })
  }
}
export const GET_TYPE_CATEGORY_MB = '[SHAPES] GET TYPE CATEGORY'
export function getTypeCategory() {
  return (dispatch) => {
    axios.post(`${API_SERVER_URL}/api/types/read/types`).then((response) => {
      const { types, success } = response.data
      if (success === true) {
        dispatch({
          type: GET_TYPE_CATEGORY_MB,
          typeCategory: types,
        })
      } else {
        Promise.all([
          dispatch({
            type: GET_MAIN_BACKGROUND,
          }),
        ]).then(() => dispatch(getMainBackground()))
      }
    })
  }
}

export const ADD_MAIN_BACKGROUND = '[MAIN_BACKGROUND] ADD MAIN BACKGROUND'
export function addMainBackground(data, type_category_id) {
  var newData = {
    shape_category_id: data.shape_category_id,
    name: data.main_background_name,
    thumbnail: data.thumbnail[0].name,
    thumbnailsm: data.thumbnailsm[0].name,
    wall_mask_layer: data.wall_mask_layer.length
      ? data.wall_mask_layer[0].name
      : null,
    occlusion_layer: data.occlusion_layer.length
      ? data.occlusion_layer[0].name
      : null,
    type_category_id: type_category_id,
    room_flag: data.room_flag,
  }

  return (dispatch) => {
    axios
      .post(`${API_SERVER_URL}/api/mainbackground/insert/main_background`, {
        ...newData,
      })
      .then((res) => {
        if (res.data.success === true) {
          dispatch(uploadLoading(true))
          if (process.env.REACT_APP_MODE === 'production') {
            uploadFiles(
              data.thumbnail.filter((file) => file.originFileObj !== undefined),
              `uploads/mainbackground/${
                res.data.mainbackground.guid + '_' + res.data.mainbackground.id
              }/`,
            )
              .then((thumbnail) => {
                uploadFiles(
                  data.thumbnailsm.filter(
                    (file) => file.originFileObj !== undefined,
                  ),
                  `uploads/mainbackground/${
                    res.data.mainbackground.guid +
                    '_' +
                    res.data.mainbackground.id
                  }/`,
                )
                  .then((thumbnailsm) => {
                    uploadFiles(
                      data.wall_mask_layer.filter(
                        (file) => file.originFileObj !== undefined,
                      ),
                      `uploads/mainbackground/${
                        res.data.mainbackground.guid +
                        '_' +
                        res.data.mainbackground.id
                      }/`,
                    )
                      .then((wall_mask_layer) => {
                        uploadFiles(
                          data.occlusion_layer.filter(
                            (file) => file.originFileObj !== undefined,
                          ),
                          `uploads/mainbackground/${
                            res.data.mainbackground.guid +
                            '_' +
                            res.data.mainbackground.id
                          }/`,
                        )
                          .then((occlusion_layer) => {
                            const uploadData = [
                              {
                                fieldName: 'thumbnail',
                                type: 'single',
                                data: thumbnail,
                                name: data.main_background_name,
                              },
                              {
                                fieldName: 'thumbnailsm',
                                type: 'single',
                                data: thumbnailsm,
                              },
                              {
                                fieldName: 'wall_mask_layer',
                                type: 'single',
                                data: wall_mask_layer,
                              },
                              {
                                fieldName: 'occlusion_layer',
                                type: 'single',
                                data: occlusion_layer,
                              },
                            ]

                            axios
                              .post(
                                `${API_SERVER_URL}/api/base/updateUploadData`,
                                {
                                  id: res.data.mainbackground.id,
                                  tableName: 'mainbackground',
                                  data: uploadData,
                                },
                              )
                              .then((response) => {
                                const { success } = response.data
                                if (success === true) {
                                  dispatch(uploadLoading(false))
                                  dispatch(
                                    showMessage({
                                      message: 'Successfully Inserted',
                                      autoHideDuration: 2000,
                                      anchorOrigin: {
                                        vertical: 'top',
                                        horizontal: 'right',
                                      },
                                      variant: 'success',
                                    }),
                                  )
                                  Promise.all([
                                    dispatch({
                                      type: ADD_MAIN_BACKGROUND,
                                    }),
                                  ]).then(() => dispatch(getMainBackground()))
                                } else {
                                  dispatch(uploadLoading(false))
                                  dispatch(
                                    showMessage({
                                      message: 'Failed to update uploaded data',
                                      autoHideDuration: 2000,
                                      anchorOrigin: {
                                        vertical: 'top',
                                        horizontal: 'right',
                                      },
                                      variant: 'error',
                                    }),
                                  )
                                }
                              })
                          })
                          .catch((err) => {
                            console.log(
                              'failed to upload mainbackground occlusion layer:',
                              err,
                            )
                            throw new Error(
                              'failed to upload mainbackground occlusion layer',
                            )
                          })
                      })
                      .catch((err) => {
                        console.log(
                          'failed to upload mainbackground wall mask layer:',
                          err,
                        )
                        throw new Error(
                          'failed to upload mainbackground wall mask layer',
                        )
                      })
                  })
                  .catch((err) => {
                    console.log(
                      'failed to upload mainbackground small thumbnail:',
                      err,
                    )
                    throw new Error(
                      'failed to upload mainbackground small thumbnail',
                    )
                  })
              })
              .catch((err) => {
                console.log('Failed to upload data to S3:', err)
                dispatch(uploadLoading(false))
                dispatch(
                  showMessage({
                    message: 'Failed to upload data to S3: ' + err.message,
                    autoHideDuration: 2000,
                    anchorOrigin: {
                      vertical: 'top',
                      horizontal: 'right',
                    },
                    variant: 'error',
                  }),
                )
              })
          } else {
            const formData = new FormData()
            formData.append('guid', res.data.mainbackground.guid)
            formData.append('id', res.data.mainbackground.id)
            formData.append('thumbnail', data.thumbnail[0].originFileObj)
            formData.append('thumbnailsm', data.thumbnailsm[0].originFileObj)
            if (data.wall_mask_layer.length) {
              formData.append(
                'wall_mask_layer',
                data.wall_mask_layer[0].originFileObj,
              )
            }
            if (data.occlusion_layer.length) {
              formData.append(
                'occlusion_layer',
                data.occlusion_layer[0].originFileObj,
              )
            }

            axios
              .post(`${API_SERVER_URL}/api/mainbackground/upload`, formData)
              .then((response) => {
                const { success } = response.data
                if (success === true) {
                  axios.post(`${API_SERVER_URL}/uploads/clear`).then((res) => {
                    if (res.data.success === true) {
                      dispatch(uploadLoading(false))
                      dispatch(
                        showMessage({
                          message: 'Successfully Inserted',
                          autoHideDuration: 2000,
                          anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                          },
                          variant: 'success',
                        }),
                      )
                      Promise.all([
                        dispatch({
                          type: ADD_MAIN_BACKGROUND,
                        }),
                      ]).then(() => dispatch(getMainBackground()))
                    } else {
                      throw new Error('/uploads/clear success false')
                    }
                  })
                } else {
                  throw new Error('success false')
                }
              })
              .catch((err) => {
                console.log('Failed to upload data:', err)
                dispatch(uploadLoading(false))
                dispatch(
                  showMessage({
                    message: 'Failed to upload data: ' + err.message,
                    autoHideDuration: 2000,
                    anchorOrigin: {
                      vertical: 'top',
                      horizontal: 'right',
                    },
                    variant: 'error',
                  }),
                )
              })
          }
        } else {
          throw new Error('insert success false')
        }
      })
      .catch((err) => {
        dispatch(
          showMessage({
            message: 'Failed to add MAIN BACKGROUND data: ' + err.message,
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'error',
          }),
        )

        console.log('Failed to add MAIN BACKGROUND data', err)
      })
  }
}

export const DELETE_MAIN_BACKGROUND = '[MAIN_BACKGROUND] DELETE MAIN BACKGROUND'
export function deleteMainBackground(record) {
  return (dispatch) => {
    axios
      .post(`${API_SERVER_URL}/api/mainbackground/remove/main_background`, {
        id: record.id,
      })
      .then((response) => {
        const { success } = response.data
        if (success === true) {
          var p1 = new Promise((resolve, reject) => {
            dispatch(uploadLoading(true))
            if (process.env.REACT_APP_MODE === 'production') {
              const data = []
              data.push(record.thumbnail)
              data.push(record.thumbnailsm)
              record.wall_mask_layer && data.push(record.wall_mask_layer)
              record.occlusion_layer && data.push(record.occlusion_layer)
              deleteFiles(data)
                .then((res) => {
                  resolve('success')
                })
                .catch((err) => {
                  console.log(err)
                  reject(err)
                })
            } else {
              resolve('success')
            }
          })
          p1.then((p1Value) => {
            dispatch(
              showMessage({
                message: 'Successfully Deleted',
                autoHideDuration: 2000,
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
                },
                variant: 'success',
              }),
            )
          })
            .catch((err) => {
              dispatch(
                showMessage({
                  message: 'AWS S3 DELETE MAIN_BACKGROUND ERROR',
                  autoHideDuration: 4000,
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                  },
                  variant: 'error',
                }),
              )
              return
            })
            .finally(() => {
              dispatch(uploadLoading(false))
              Promise.all([
                dispatch({
                  type: DELETE_MAIN_BACKGROUND,
                }),
              ]).then(() => dispatch(getMainBackground()))
            })
        } else {
          // dispatch(showMessage({
          //     message: 'Some Door Colors are using this style',
          //     autoHideDuration: 4000,
          //     anchorOrigin: {
          //         vertical: 'top',
          //         horizontal: 'right'
          //     },
          //     variant: 'warning'
          // }));
          throw new Error('success false')
        }
      })
      .catch((err) => {
        dispatch(
          showMessage({
            message: 'Failed to delete MAIN BACKGROUND data: ' + err.message,
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'error',
          }),
        )
        console.log('Failed to delete MAIN BACKGROUND data:', err)
      })
  }
}

export const UPDATE_MAIN_BACKGROUND = '[MAIN_BACKGROUND] UPDATE MAIN BACKGROUND'
export function updateMainBackground(data, id, guid, type_category_id) {
  console.log('updateMainBackground', data)
  var updateData = {
    id: id,
    type_category_id: type_category_id,
    shape_category_id: data.shape_category_id,
    name: data.main_background_name,
    room_flag: data.room_flag,
    thumbnail: jsonArray(data.thumbnail)[0],
    thumbnailsm: jsonArray(data.thumbnailsm)[0],
    wall_mask_layer: data.wall_mask_layer.length
      ? jsonArray(data.wall_mask_layer)[0]
      : null,
    occlusion_layer: data.occlusion_layer.length
      ? jsonArray(data.occlusion_layer)[0]
      : null,
  }

  return (dispatch) => {
    axios
      .post(`${API_SERVER_URL}/api/mainbackground/update/main_background`, {
        ...updateData,
      })
      .then((res) => {
        if (res.data.success === true) {
          dispatch(uploadLoading(true))
          if (process.env.REACT_APP_MODE === 'production') {
            putFiles(
              data.thumbnail.filter((file) => file.originFileObj !== undefined),
              `uploads/mainbackground/${guid + '_' + id}/`,
            )
              .then((thumbnail) => {
                putFiles(
                  data.thumbnailsm.filter(
                    (file) => file.originFileObj !== undefined,
                  ),
                  `uploads/mainbackground/${guid + '_' + id}/`,
                )
                  .then((thumbnailsm) => {
                    putFiles(
                      data.wall_mask_layer.filter(
                        (file) => file.originFileObj !== undefined,
                      ),
                      `uploads/mainbackground/${guid + '_' + id}/`,
                    )
                      .then((wall_mask_layer) => {
                        putFiles(
                          data.occlusion_layer.filter(
                            (file) => file.originFileObj !== undefined,
                          ),
                          `uploads/mainbackground/${guid + '_' + id}/`,
                        )
                          .then((occlusion_layer) => {
                            const uploadData = [
                              {
                                fieldName: 'thumbnail',
                                type: 'single',
                                data: thumbnail,
                                name: data.main_background_name,
                              },
                              {
                                fieldName: 'thumbnailsm',
                                type: 'single',
                                data: thumbnailsm,
                              },
                              {
                                fieldName: 'wall_mask_layer',
                                type: 'single',
                                data: wall_mask_layer,
                              },
                              {
                                fieldName: 'occlusion_layer',
                                type: 'single',
                                data: occlusion_layer,
                              },
                            ]

                            axios
                              .post(
                                `${API_SERVER_URL}/api/base/updateUploadData`,
                                {
                                  id: id,
                                  tableName: 'mainbackground',
                                  data: uploadData,
                                },
                              )
                              .then((response) => {
                                const { success } = response.data
                                if (success === true) {
                                  dispatch(uploadLoading(false))
                                  dispatch(
                                    showMessage({
                                      message: 'Successfully Updated',
                                      autoHideDuration: 2000,
                                      anchorOrigin: {
                                        vertical: 'top',
                                        horizontal: 'right',
                                      },
                                      variant: 'success',
                                    }),
                                  )
                                  Promise.all([
                                    dispatch({
                                      type: UPDATE_MAIN_BACKGROUND,
                                    }),
                                  ]).then(() => dispatch(getMainBackground()))
                                } else {
                                  dispatch(uploadLoading(false))
                                  dispatch(
                                    showMessage({
                                      message: 'Failed to update uploaded data',
                                      autoHideDuration: 2000,
                                      anchorOrigin: {
                                        vertical: 'top',
                                        horizontal: 'right',
                                      },
                                      variant: 'error',
                                    }),
                                  )
                                }
                              })
                          })
                          .catch((err) => {
                            console.log(
                              'failed to put mainbackground occlusion layer: ',
                              err,
                            )
                            throw new Error(
                              'failed to put mainbackground occlusion layer',
                            )
                          })
                      })
                      .catch((err) => {
                        console.log(
                          'failed to put mainbackground wall mask layer: ',
                          err,
                        )
                        throw new Error(
                          'failed to put mainbackground wall mask layer',
                        )
                      })
                  })
                  .catch((err) => {
                    console.log(
                      'failed to put mainbackground small thumbnail: ',
                      err,
                    )
                    throw new Error(
                      'failed to put mainbackground small thumbnail',
                    )
                  })
              })
              .catch((err) => {
                console.log('Failed to put data to S3: ', err)
                dispatch(uploadLoading(false))
                dispatch(
                  showMessage({
                    message: 'Failed to put data to S3: ' + err.message,
                    autoHideDuration: 2000,
                    anchorOrigin: {
                      vertical: 'top',
                      horizontal: 'right',
                    },
                    variant: 'error',
                  }),
                )
              })
          } else {
            if (
              data.thumbnail[0].originFileObj !== undefined ||
              data.thumbnailsm[0].originFileObj !== undefined ||
              (data.wall_mask_layer.length !== 0 &&
                data.wall_mask_layer[0].originFileObj !== undefined) ||
              (data.occlusion_layer.length !== 0 &&
                data.occlusion_layer[0].originFileObj !== undefined)
            ) {
              const formData = new FormData()
              formData.append('guid', guid)
              formData.append('id', id)
              data.thumbnail[0].originFileObj !== undefined &&
                formData.append('thumbnail', data.thumbnail[0].originFileObj)
              data.thumbnailsm[0].originFileObj !== undefined &&
                formData.append(
                  'thumbnailsm',
                  data.thumbnailsm[0].originFileObj,
                )
              if (
                data.wall_mask_layer.length !== 0 &&
                data.wall_mask_layer[0].originFileObj !== undefined
              ) {
                formData.append(
                  'wall_mask_layer',
                  data.wall_mask_layer[0].originFileObj,
                )
              }
              if (
                data.occlusion_layer.length !== 0 &&
                data.occlusion_layer[0].originFileObj !== undefined
              ) {
                formData.append(
                  'occlusion_layer',
                  data.occlusion_layer[0].originFileObj,
                )
              }

              axios
                .post(`${API_SERVER_URL}/api/mainbackground/upload`, formData)
                .then((response) => {
                  const { success } = response.data
                  if (success === true) {
                    axios
                      .post(`${API_SERVER_URL}/uploads/clear`)
                      .then((res) => {
                        if (res.data.success === true) {
                          dispatch(uploadLoading(false))
                          dispatch(
                            showMessage({
                              message: 'Successfully Updated',
                              autoHideDuration: 2000,
                              anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                              },
                              variant: 'success',
                            }),
                          )
                          Promise.all([
                            dispatch({
                              type: UPDATE_MAIN_BACKGROUND,
                            }),
                          ]).then(() => dispatch(getMainBackground()))
                        } else {
                          throw new Error('/uploads/clear success false')
                        }
                      })
                  } else {
                    throw new Error('success false')
                  }
                })
                .catch((err) => {
                  console.log('Failed to upload data:', err)
                  dispatch(uploadLoading(false))
                  dispatch(
                    showMessage({
                      message: 'Failed to upload data: ' + err.message,
                      autoHideDuration: 2000,
                      anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                      },
                      variant: 'error',
                    }),
                  )
                })
            } else {
              dispatch(uploadLoading(false))
              dispatch(
                showMessage({
                  message: 'Successfully Updated',
                  autoHideDuration: 2000,
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                  },
                  variant: 'success',
                }),
              )
              Promise.all([
                dispatch({
                  type: UPDATE_MAIN_BACKGROUND,
                }),
              ]).then(() => dispatch(getMainBackground()))
            }
          }
        } else {
          throw new Error('update success false')
        }
      })
      .catch((err) => {
        console.log('Failed to update MAIN BACKGROUND data', err)
        dispatch(
          showMessage({
            message: 'Failed to update MAIN BACKGROUND data: ' + err.message,
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'error',
          }),
        )
      })
  }
}

export const SET_MAIN_BACKGROUND_SORT_ORDER = '[MAIN_BACKGROUND] SET SORT ORDER'
export function setSortOrder(data) {
  return (dispatch) => {
    dispatch(uploadLoading(true))
    axios
      .post(`${API_SERVER_URL}/api/base/setSortOrder`, {
        id: data.id,
        sort_order: data.sort_order,
        table_name: 'mainbackground',
      })
      .then((response) => {
        const { success } = response.data
        if (success === true) {
          dispatch(uploadLoading(false))
          Promise.all([
            dispatch({
              type: SET_MAIN_BACKGROUND_SORT_ORDER,
            }),
          ]).then(() => dispatch(getMainBackground()))
        } else {
          dispatch(uploadLoading(false))
        }
      })
      .catch((err) => {
        dispatch(uploadLoading(false))
        dispatch(
          showMessage({
            message: 'Failed to set sort order',
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'error',
          }),
        )
        console.log('failed to set sort order in door style.', err)
      })
  }
}

export const GET_CATALOG_DATA = '[CATALOG] GET CATALOG DATA'
export function getCatalogData() {
  return (dispatch) => {
    axios
      .post(`${API_SERVER_URL}/api/catalog/read/catalog`)
      .then((response) => {
        const { catalog } = response.data
        dispatch({
          type: GET_CATALOG_DATA,
          catalog: catalog,
        })
      })
      .catch((err) => {
        Promise.all([
          dispatch({
            type: GET_MAIN_BACKGROUND,
          }),
        ]).then(() => dispatch(getMainBackground()))
      })
  }
}
