import * as Actions from "../actions";

const initialState = {
  colorFamily: []
};

const colorfamilyReducer = function(state = initialState, action) {
  switch (action.type) {
    case Actions.GET_COLOR_FAMILY: {
      return {
        ...state,
        ...action
      };
    }
    default: {
      return state;
    }
  }
};

export default colorfamilyReducer;
