import React from 'react';
import {Redirect} from 'react-router-dom';
import {FuseUtils} from '@fuse';
import {MainBackgroundConfig} from 'app/main/mainbackground/MainBackgroundConfig';
import {TypesConfig} from 'app/main/types/TypesConfig';
import {LayerConfig} from 'app/main/layer/LayerConfig';
import {ShapesConfig} from 'app/main/shapes/ShapesConfig';
import {McategoriesConfig} from 'app/main/mcategories/McategoriesConfig';
import {CategoriesConfig} from 'app/main/categories/CategoriesConfig';
import {MoldingShapeConfig} from 'app/main/molding_shape/MoldingShapeConfig';
import {LoginConfig} from 'app/main/login/LoginConfig';
import {UsersAppConfig} from 'app/main/user/UsersAppConfig';
import {HotspotsConfig} from 'app/main/hotspots/HotspotsConfig';
import {InfoPointsConfig} from 'app/main/infopoints/InfoPointsConfig';
import {BrandConfig} from 'app/main/brand/BrandConfig';
import {CatalogConfig} from 'app/main/catalog/CatalogConfig';
import {ColorConfig} from 'app/main/color/ColorConfig';
import {ColorFamilyConfig} from 'app/main/colorfamily/ColorFamilyConfig';
import {ProjectLogConfig} from 'app/main/projectlog/ProjectLogConfig';
import {EmailLogConfig} from 'app/main/emaillog/EmailLogConfig';
import {DealersAppConfig} from 'app/main/dealer/DealersAppConfig';
import {DealerLoginConfig} from 'app/main/dealer_area/LoginConfig';
import {UrlGeneratorConfig} from 'app/main/urlgenerator/UrlGeneratorConfig';

import {HomeConfig} from 'app/main/dealer_area/menu/home/HomeConfig';
import {LeadsConfig} from 'app/main/dealer_area/menu/leads/LeadsConfig';
import {ContentConfig} from 'app/main/dealer_area/menu/content/ContentConfig';
import {VisualIdentityConfig} from 'app/main/dealer_area/menu/visualidentity/VisualIdentityConfig';
import {SubscriptionConfig} from 'app/main/dealer_area/menu/subscription/SubscriptionConfig';
import { PcategoriesConfig } from 'app/main/pcategories/PcategoriesConfig';

import isDealerArea from '../../validateDealer';

const routeConfigs = [
    BrandConfig,
    CatalogConfig,
    MainBackgroundConfig,
    TypesConfig,
    LayerConfig,
    ShapesConfig,
    CategoriesConfig,
    PcategoriesConfig,
    LoginConfig,
    UsersAppConfig,
    HotspotsConfig,
    InfoPointsConfig,
    MoldingShapeConfig,
    McategoriesConfig,
    ProjectLogConfig,
    EmailLogConfig,
    DealersAppConfig,
    UrlGeneratorConfig,
    ColorConfig,
    ColorFamilyConfig,
    DealerLoginConfig,
    HomeConfig,
    LeadsConfig,
    ContentConfig,
    VisualIdentityConfig,
    SubscriptionConfig,
]

const routes = [
    ...FuseUtils.generateRoutesFromConfigs(routeConfigs),
    !isDealerArea ? {
        path     : '/',
        exact    : true,
        component: () => <Redirect to="/brand"/>
    } : {
        path     : '/admin',
        exact    : true,
        component: () => <Redirect to="/admin/home"/>
    },
];

export default routes;
