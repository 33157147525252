export const API_SERVER_URL = process.env.REACT_APP_API_URL;
export const API_KV_URL = process.env.REACT_APP_KV_URL;
export const CABINET_DATA = [
    {id : 0, value:'Wall Cabinets', name: 'Wall Cabinet'},
    {id : 1, value:'Base Cabinets', name: 'Base Cabinet'},
    {id : 2, value:'Island Cabinets', name: 'Island Cabinet'},
    {id : 3, value:'Countertop', name: 'Countertop'},
    {id : 4, value:'Floor', name: 'Floor'},
    {id : 5, value:'Backsplash', name: 'Backsplash'},
    {id : 6, value:'Appliances', name: 'Appliance'},
    {id : 7, value:'Raw Wall', name: 'Raw Wall'},
    {id : 7, value:'Handles', name: 'Handles'}
];
export const DOORSTYLE_TYPE_DATA = [
    {id : 0, value:'Allure', name: 'Allure'},
    {id : 1, value:'Illume', name: 'Illume'},
    {id : 2, value:'Value Premium', name: 'Value Premium'},
    {id : 3, value:'Quest', name: 'Quest'},
];
export const HOTSPOTS_TYPES = [
    {value: "Base Cabinets", text: "Base Cabinet"},
    {value: "Wall Cabinets", text: "Wall Cabinet"},
    {value: "Island Cabinets", text: "Island Cabinet"},
    {value: "Crown Moldings", text: "Crown Molding"},
    {value: "Countertop", text: "Countertop"},
    {value: "Backsplash", text: "Backsplash"},
    {value: "Floor", text: "Floor"},
    {value: "Appliances", text: "Appliance"},
    {value: "Wall color", text: "Wall Color"},
    {value:'Handles', text: 'Handles'},
    {value: "Navigation", text: "Navigation"},
    {value: "LookAt", text: "Look At"}
];

export const UI_MODES = [
    {
        id: "fabuwood",
        name: "Fabuwood"
    },
    {
        id: "fabuwood360",
        name: "Fabuwood Combined"
    },
    {
        id: "rtastore",
        name: "theRTAstore"
    },
    {
        id: "addokv",
        name: "Addo KV"
    },
    {
        id: "general",
        name: "General UI"
    },
    {
        id: "cnccabinetry",
        name: "CNC Cabinetry"
    },
];

const AWS = require('aws-sdk');
const crypto = require('crypto');
const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWS_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET
});

export const uploadFiles = (files, fileDir) => {
    // Read content from the file
    var url = [];
    var cnt = 0;
    return new Promise((resolve, reject) => {
        if(files.length === 0) {
            resolve("empty");
        }
        files.forEach((file) => {
            const fileReader = new FileReader()
            fileReader.onload = (e) => {
                // Read the file content
                const fileContent = e.target.result;

                // Compute the MD5 checksum of the file content
                const buffer = Buffer.from(fileContent);
                const md5Checksum = crypto.createHash('md5').update(buffer).digest('base64');

                // S3 upload parameters
                const filePath = fileDir + file.name;
                const params = {
                    Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                    Key: filePath,
                    Body: fileContent,
                    ContentMD5: md5Checksum,
                    //ACL:'public-read'
                };
                s3.upload(params, function(err, data) {
                    if (err) {
                        console.log(err, data);
                        reject(err);
                    }
                    url.push(data.Location);
                    cnt ++;
                    if(cnt === files.length) {
                        console.log('amazon dataLocation', url)
                        resolve(url);
                    }
                });
            };
            fileReader.readAsArrayBuffer(file.originFileObj);
        });
    });
};

export const putFiles = (files, fileDir) => {
    // Read content from the file
    var url = [];
    var cnt = 0;
    return new Promise((resolve, reject) => {
        if(files.length === 0) {
            resolve("empty");
        }
        files.forEach((file) => {
            const fileReader = new FileReader()
            fileReader.onload = (e) => {
                // Read the file content
                const fileContent = e.target.result;

                // Compute the MD5 checksum of the file content
                const buffer = Buffer.from(fileContent);
                const md5Checksum = crypto.createHash('md5').update(buffer).digest('base64');

                // S3 upload parameters
                const filePath = fileDir + file.name;
                const params = {
                    Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                    Key: filePath,
                    Body: fileContent,
                    ContentType: file.type,
                    ContentMD5: md5Checksum,
                    //ACL:'public-read'
                };
                s3.upload(params, function(err, data) {
                    if (err) {
                        console.log(err, data);
                        reject(err);
                    }
                    url.push(data.Location);
                    cnt ++;
                    if(cnt === files.length) {
                        console.log('amazon dataLocation',url)
                        resolve(url);
                    }
                });
            };
            fileReader.readAsArrayBuffer(file.originFileObj);
        });
    });
};

export const deleteFiles = (files) => {
    var cnt = 0;
    var p1 = new Promise((resolve, reject) => {
        if(files.length === 0) {
            resolve("empty");
        }
        files.forEach((file) => {
            const params = {
                Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                Key: file,
            };
            s3.deleteObject(params, function(err, data) {
                if (err) {
                    console.log(err, data);
                    reject(err);
                }
                cnt ++;
                if(cnt === files.length) {
                    resolve("success");
                }
            });
        });
    });
    return p1;
}

export const jsonArray = (data) => {
    const items = [];
    if (data === undefined) return items;
    data.forEach(fElement => {
        if(fElement.originFileObj === undefined)
            items.push(fElement.url);
        else
            items.push(fElement.name);
    })
    return items;
}

export const getData = (data, fieldName, index) => {
    var result = [];
    var tmpArray = data.filter(element => element[fieldName] === index);
    console.log('tmpArray', tmpArray);
    tmpArray.forEach(iElement => {
        if(data.some(element => element[fieldName] === iElement.id)) {
            iElement.children = getData(data, fieldName, iElement.id);
            result.push({
                ...iElement
            })
        }
        else {
            result.push({
                ...iElement
            })
        }
    })

    result.sort(function(a,b){
        return a.sort_order - b.sort_order;
    });
    return result;
}

export const beautifyJSON = (data) => {
    var fieldValue = '';
    var result = '';
    // console.log("data", data);
    JSON.parse(data, (key, value) => {
        if(key !== '') {
            if(!isNaN(parseInt(key, 10))) {
                fieldValue += '\t' + value + '\n';
            }
            else {
                result += key + ' : ' + fieldValue + '\n';
                fieldValue = '';
            }
        }
        return value;
    })
    return result.split('\n');
}

export const getDoorStyleTreeData = (doorCategory, doorStyle, parentID, path) => {
    var result = []
    var filterData = doorCategory.filter((fElement) => {
        return fElement.parent_door_category_id === parentID;
    })
    filterData.forEach(x => {
        var m_path1 = path;
        if(path === "")
            m_path1 = x.name;
        else
            m_path1 =  x.name + ' - ' + m_path1;
        var item = {
            type: "door-category",
            id: x.id,
            name: x.name,
            path: m_path1,
            items: [],
        };
        if (doorCategory.some((fElement) => { return fElement.parent_door_category_id === x.id;})) {
            item.items = getDoorStyleTreeData(doorCategory, doorStyle, x.id, m_path1);
        }
        var filterDoorStyles = doorStyle.filter((fElement) => {
            return fElement.door_category_id === x.id;
        })
        var tempItems = [];
        filterDoorStyles.forEach(y => {
            var m_path2 = m_path1;
            m_path2 = y.door_style_name + ' - ' +  m_path1;
            var doorStyleItem = {
                id: y.id,
                type: "door-style",
                name: y.door_style_name,
                path: m_path2,
            }
            tempItems.push(doorStyleItem);
        })
        item.items = [...item.items, ...tempItems];
        result.push(item);
    })
    return result;
}

export const addTimestampToFilename = (filename) => {
    return filename.split('.').slice(0, -1).join('.') + '_' + require('time-stamp')('YYYYMMDDmmss') + '.' + filename.split('.').slice(-1);
}

export const MAX_PH_CNT = 9;

export const PH_ARRAY = Array.from({length: MAX_PH_CNT}, (v, k) => k + 1);
