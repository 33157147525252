import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  FormControl,
  OutlinedInput,
  Fab,
  InputLabel,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import AddIcon from "@material-ui/icons/Add";
import { Form, Input, Upload } from "antd";

const styles = (theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    width: "100%",
    margin: theme.spacing(1),
  },
});

class InfoPointDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      description: "",
      image: [],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.value.title !== prevProps.value.title) {
      this.setState({ title: this.props.value.title });
    }
    if (this.props.value.description !== prevProps.value.description) {
      this.setState({ description: this.props.value.description });
    }
    if (this.props.value.image !== prevProps.value.image) {
      this.setState({ image: this.props.value.image });
    }
  }

  handleSave = () => {
    const { onClose } = this.props;

    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log("validateFields", values);
        const { title, description, image } = values;
        onClose({ mode: "save", title, description, image });
      }
    });
  };

  handleDelete = () => {
    const { onClose } = this.props;
    onClose({ mode: "delete" });
  };

  handleClose = () => {
    const { onClose } = this.props;
    onClose(null);
  };

  normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  render() {
    const { classes, value, open } = this.props;
    const { id, x, y } = value;
    const { title, description, image } = this.state;
    const { getFieldDecorator } = this.props.form;

    return (
      <Dialog
        onClose={this.handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle id="simple-dialog-title">
          {id == null ? "Add InfoPoint" : "Edit InfoPoint"}
        </DialogTitle>
        <DialogContent>
          <Form
            layout="vertical"
            variant="outlined"
            style={{ minWidth: "500px" }}
          >
            <Form.Item key="x" label="X">
              {getFieldDecorator("x", {
                initialValue: x.toFixed(2),
                rules: [
                  {
                    required: true,
                    message: "Enter a value.",
                  },
                ],
              })(<Input placeholder="Enter a value" disabled type="number" />)}
            </Form.Item>
            <Form.Item key="y" label="Y">
              {getFieldDecorator("y", {
                initialValue: y.toFixed(2),
                rules: [
                  {
                    required: true,
                    message: "Enter a value.",
                  },
                ],
              })(<Input placeholder="Enter a value" disabled type="number" />)}
            </Form.Item>

            <Form.Item key="title" label="Title">
              {getFieldDecorator("title", {
                initialValue: title,
                rules: [
                  {
                    required: true,
                    message: "Enter a title.",
                  },
                ],
              })(
                <Input
                  placeholder="Enter a title"
                  onChange={(e) => {
                    this.setState({ title: e.target.value });
                  }}
                />
              )}
            </Form.Item>

            <Form.Item key="description" label="Description">
              {getFieldDecorator("description", {
                initialValue: description,
                rules: [
                  {
                    required: true,
                    message: "Enter a description.",
                  },
                ],
              })(
                <Input
                  placeholder="Enter a description"
                  onChange={(e) => {
                    this.setState({ description: e.target.value });
                  }}
                />
              )}
            </Form.Item>

            <Form.Item key="image" label="Image">
              {getFieldDecorator("image", {
                initialValue: image,
                valuePropName: "fileList",
                rules: [
                  {
                    required: true,
                    message: "Please select an image!",
                  },
                ],
                getValueFromEvent: this.normFile,
              })(
                <Upload
                  listType="picture-card"
                  className="upload-list-inline"
                  onRemove={(file) => {
                    this.setState({
                      image: [],
                    });
                  }}
                  beforeUpload={(file) => {
                    this.setState({
                      image: [file],
                    });
                    return false;
                  }}
                >
                  {image.length > 0 ? null : (
                    <div>
                      <AddIcon />
                      <div className="ant-upload-text">
                        {id === null ? "Upload" : "Change & Upload"}
                      </div>
                    </div>
                  )}
                </Upload>
              )}
            </Form.Item>
          </Form>
        </DialogContent>
        <DialogActions>
          {id != null && (
            <Fab
              variant="extended"
              color="secondary"
              className={classes.button}
              onClick={this.handleDelete}
            >
              <DeleteIcon />
              Delete
            </Fab>
          )}
          <Fab
            variant="extended"
            color="primary"
            className={classes.button}
            onClick={this.handleSave}
          >
            <SaveIcon />
            Save
          </Fab>
        </DialogActions>
      </Dialog>
    );
  }
}

const InfoPointDialogComponent = Form.create()(InfoPointDialog);

export default withStyles(styles, { withTheme: true })(
  InfoPointDialogComponent
);
