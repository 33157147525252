import axios from "axios";
import { showMessage } from "app/store/actions/fuse";
import {
  API_SERVER_URL,
  jsonArray,
  uploadFiles,
  putFiles,
  deleteFiles,
  addTimestampToFilename
} from "app/main/config";
import { uploadLoading } from "app/store/actions/fuse";

export const GET_PCATEGORIES = "[PCATEGORIES] GET PCATEGORIES";
export function getPCategories() {
  return dispatch => {
    axios
      .post(`${API_SERVER_URL}/api/pcategories/read/pcategories`)
      .then(response => {
        const { pcategories } = response.data;
        dispatch({
          type: GET_PCATEGORIES,
          pcategories: pcategories
        });
      })
      .catch(err => {
        dispatch(
          showMessage({
            message: "Failed to load PCATEGORIES data",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right"
            },
            variant: "error"
          })
        );
      });
  };
}

export const ADD_PCATEGORIES = "[PCATEGORIES] ADD PCATEGORIES";
export function addPCategories(data) {
  var newData = {
    category_name: data.category_name,
    parent_category_name: data.parent_category_name,
    doorstyle_type: data.doorstyle_type,
    texture_url: data.texture_url.length === 0 ? "" : data.texture_url[0].name,
  };

  if (
    data.texture_url.length !== 0 &&
    data.texture_url[0].originFileObj !== undefined
  )
    data.texture_url[0].name = addTimestampToFilename(data.texture_url[0].name);

  const formData = new FormData();
  formData.append(
    "file",
    data.texture_url.length === 0 ? null : data.texture_url[0].originFileObj
  );

  return (dispatch) => {
    axios
      .post(`${API_SERVER_URL}/api/pcategories/insert/pcategories`, {
        ...newData
      })
      .then(res => {
        dispatch(uploadLoading(true));
        if (process.env.REACT_APP_MODE === "production") {
              uploadFiles(
                data.texture_url.filter(
                  file => file.originFileObj !== undefined
                ),
                `uploads/pcategories/${res.data.pcategories.guid +
                  "_" +
                  res.data.pcategories.id}/texture_url/`
              )
                .then(texture_url => {
                  const uploadData = [
                    {
                      fieldName: "texture_url",
                      type: "single",
                      data: texture_url
                    }
                  ];
                  axios
                    .post(`${API_SERVER_URL}/api/base/updateUploadData`, {
                      id: res.data.pcategories.id,
                      tableName: "pcategories",
                      data: uploadData
                    })
                    .then(response => {
                      const { success } = response.data;
                      dispatch(uploadLoading(false));
                      if (success === true) {
                        dispatch(
                          showMessage({
                            message: "Successfully Inserted",
                            autoHideDuration: 2000,
                            anchorOrigin: {
                              vertical: "top",
                              horizontal: "right"
                            },
                            variant: "success"
                          })
                        );
                        dispatch(getPCategories());
                      } else {
                        console.log(success);
                      }
                    });
            })
            .catch(err => {
              console.log("failed to upload icon thumbnail. ", err);
              return;
            });
        } else {
          formData.append("guid", res.data.pcategories.guid);
          formData.append("id", res.data.pcategories.id);
          axios
            .post(`${API_SERVER_URL}/api/pcategories/upload`, formData)
            .then(response => {
              const { success } = response.data;
              dispatch(uploadLoading(false));
              if (success === true) {
                axios.post(`${API_SERVER_URL}/uploads/clear`).then(res => {
                  if (res.data.success === true) {
                    dispatch(
                      showMessage({
                        message: "Successfully Inserted",
                        autoHideDuration: 2000,
                        anchorOrigin: {
                          vertical: "top",
                          horizontal: "right"
                        },
                        variant: "success"
                      })
                    );
                    dispatch(getPCategories());
                  }
                });
              }
            });
        }
      })
      .catch(err => {
        dispatch(
          showMessage({
            message: "Failed to add PCATEGORIES data",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right"
            },
            variant: "error"
          })
        );

        console.log("err", err);
      });
  };
}

export const DELETE_PCATEGORIES = "[PCATEGORIES] DELETE PCATEGORIES";
export function deletePCategories(record) {
  return ((dispatch) => {
    var p1 = new Promise((resolve, reject) => {
      dispatch(uploadLoading(true));
      if (process.env.REACT_APP_MODE === "production") {
        const data = [];
        data.push(record.texture_url);
        deleteFiles(data).then(res => {
          resolve("success");
        })
          .catch(err => {
            console.log(err);
            reject(err);
          })
      } else {
        resolve("success");
      }
    })
    p1.then(p1Value => {
      axios.post(`${API_SERVER_URL}/api/pcategories/remove/pcategories`, {
        id: record.id
      })
        .then(response => {
          const { success } = response.data;
          dispatch(uploadLoading(false));
          if (success === true) {
            dispatch(showMessage({
              message: 'Successfully Deleted',
              autoHideDuration: 2000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
              }, variant: 'success'
            }));
            Promise.all([
              dispatch({
                type: DELETE_PCATEGORIES,
              })
            ]).then(() => dispatch(getPCategories()))
          }
        }).catch(err => {
          dispatch(uploadLoading(false));
          dispatch(showMessage({
            message: 'Failed to delete PCATEGORIES data',
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right'
            }, variant: 'error'
          }));

          console.log("err", err);
        });
    })
      .catch(err => {
        dispatch(uploadLoading(false));
        dispatch(showMessage({
          message: 'AWS S3 DELETE CATEGORIES ERROR',
          autoHideDuration: 4000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          },
          variant: 'error'
        }));
        return;
      })
  })
}

export const UPDATE_PCATEGORIES = '[PCATEGORIES] UPDATE PCATEGORIES';
export function updatePCategories(data, id, guid) {
    var updateData = {
      id          : id,
      category_name: data.category_name,
      parent_category_name: data.parent_category_name,
      doorstyle_type: data.doorstyle_type,
      texture_url: jsonArray([data.texture_url[0]]) 
    }

    return ((dispatch) => {
            axios.post(`${API_SERVER_URL}/api/pcategories/update/pcategories`, {
                ...updateData
            }).then(res => {
                dispatch(uploadLoading(true));
                if(process.env.REACT_APP_MODE === "production") {
                    putFiles(data.texture_url.filter(file => file.originFileObj !== undefined), `uploads/pcategories/${guid + '_' + id}/`).then(texture_url => {                        
                        const uploadData = [
                          {
                            fieldName: "texture_url",
                            type: "single",
                            data: texture_url
                          }
                        ];
                        axios.post(`${API_SERVER_URL}/api/base/updateUploadData`, 
                            {
                                id: id,
                                tableName: "pcategories",
                                data: uploadData
                            }
                        )
                        .then(response => {
                            const {success} = response.data;
                            dispatch(uploadLoading(false));
                            if(success === true) {
                                dispatch(showMessage({
                                    message         : 'Successfully Updated',
                                    autoHideDuration: 2000,
                                    anchorOrigin    : {
                                        vertical  : 'top',
                                        horizontal: 'right'
                                    },variant : 'success'
                                }));
                                Promise.all([
                                    dispatch({
                                        type: UPDATE_PCATEGORIES
                                    })
                                ]).then(() => dispatch(getPCategories()))
                            } else {
                                console.log(success);
                            }
                        })
                    })
                    .catch(err => {
                        console.log("failed to put parent category thumbnail. ", err);
                        return;
                    })
                } else {
                    if(data.texture_url[0].originFileObj !== undefined) {
                        const formData = new FormData();
                        formData.append("file", data.texture_url[0].originFileObj);
                        formData.append("guid" , guid);
                        formData.append("id" , id);
                        axios.post(`${API_SERVER_URL}/api/pcategories/upload`, formData)
                        .then(response => {
                            const {success} = response.data;
                            dispatch(uploadLoading(false));
                            if(success === true) {
                                axios.post(`${API_SERVER_URL}/uploads/clear`)
                                .then(res => {
                                    if(res.data.success === true ){
                                        dispatch(showMessage({
                                            message         : 'Successfully Updated',
                                            autoHideDuration: 2000,
                                            anchorOrigin    : {
                                                vertical  : 'top',
                                                horizontal: 'right'
                                            },variant : 'success'
                                        }));
                                        Promise.all([
                                            dispatch({
                                                type: UPDATE_PCATEGORIES
                                            })
                                        ]).then(() => dispatch(getPCategories()))
                                    }
                                })
                            }
                        })
                    }
                    else {
                        dispatch(uploadLoading(false));
                        dispatch(showMessage({
                            message         : 'Successfully Updated',
                            autoHideDuration: 2000,
                            anchorOrigin    : {
                                vertical  : 'top',
                                horizontal: 'right'
                            },variant : 'success'
                        }));
                        Promise.all([
                            dispatch({
                                type: UPDATE_PCATEGORIES
                            })
                        ]).then(() => dispatch(getPCategories()))
                    }
                }
            }).catch(err => {
                dispatch(showMessage({
                    message         : 'Failed to update PCATEGORY data',
                    autoHideDuration: 2000,
                    anchorOrigin    : {
                        vertical  : 'top',
                        horizontal: 'right'
                    },variant : 'error'
                }));
    
                console.log("err", err);
            });    
    })
}

export const SET_PCATEGORIES_SORT_ORDER = "[PCATEGORIES] SET SORT ORDER";
export function setSortOrder(data) {
  return dispatch => {
    dispatch(uploadLoading(true));
    axios
      .post(`${API_SERVER_URL}/api/base/setSortOrder`, {
        id: data.id,
        sort_order: data.sort_order,
        table_name: "pcategories"
      })
      .then(response => {
        const { success } = response.data;
        if (success === true) {
          dispatch(uploadLoading(false));
          Promise.all([
            dispatch({
              type: SET_PCATEGORIES_SORT_ORDER
            })
          ]).then(() => dispatch(getPCategories()));
        } else {
          dispatch(uploadLoading(false));
        }
      })
      .catch(err => {
        dispatch(uploadLoading(false));
        dispatch(
          showMessage({
            message: "Failed to set sort order",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right"
            },
            variant: "error"
          })
        );
        console.log("failed to set sort order in shape style.", err);
      });
  };
}
