import axios from 'axios'
import { showMessage } from 'app/store/actions/fuse'
import {
  API_SERVER_URL,
  uploadFiles,
  putFiles,
  deleteFiles,
  jsonArray,
} from 'app/main/config'
import { uploadLoading } from 'app/store/actions/fuse'

export const LOAD_DATABASE = '[INFOPOINTS] LOAD DATABASE'
export function loadDB() {
  return (dispatch) => {
    dispatch(loadMainbackgrounds())
    dispatch(loadInfoPoints())
  }
}
export function loadMainbackgrounds() {
  return (dispatch) => {
    axios
      .post(`${API_SERVER_URL}/api/mainbackground/read/main_background`)
      .then((response) => {
        const { mainbackground, success } = response.data
        if (success === true) {
          dispatch({
            type: LOAD_DATABASE,
            mainbackgrounds: mainbackground,
          })
        }
      })
  }
}

export function loadInfoPoints() {
  return (dispatch) => {
    axios.get(`${API_SERVER_URL}/api/infopoints`).then((response) => {
      const { success, value } = response.data
      if (success === true) {
        dispatch({
          type: LOAD_DATABASE,
          infopoints: value,
        })
      }
    })
  }
}

export const INSERT_INFOPOINT = '[INFOPOINTS] INSERT INFOPOINT'
export function insertInfoPoint(insertData) {
  return (dispatch) => {
    const { x, y, mainbg_id, mainbg_guid, title, description } = insertData
    axios
      .post(`${API_SERVER_URL}/api/infopoints`, {
        x,
        y,
        mainbg_id,
        title,
        description,
        image: insertData.image[0].name,
      })
      .then(({ data: insertResponse }) => {
        console.log('insertInfoPoint insertResponse', insertResponse)
        if (insertResponse.success) {
          dispatch(uploadLoading(true))
          uploadFiles(
            insertData.image.filter((file) => file.originFileObj !== undefined),
            `uploads/infopoints/${mainbg_guid}_${mainbg_id}/`,
          )
            .then((image) => {
              axios
                .post(`${API_SERVER_URL}/api/base/updateUploadData`, {
                  id: insertResponse.value[0].id,
                  tableName: 'info_points',
                  data: [
                    {
                      fieldName: 'image',
                      type: 'single',
                      data: image,
                    },
                  ],
                })
                .then((uploadResponse) => {
                  const { success } = uploadResponse.data
                  if (success === true) {
                    dispatch(uploadLoading(false))
                    dispatch(
                      showMessage({
                        message: 'Successfully Inserted',
                        autoHideDuration: 2000,
                        anchorOrigin: {
                          vertical: 'top',
                          horizontal: 'right',
                        },
                        variant: 'success',
                      }),
                    )
                    Promise.all([
                      dispatch({
                        type: INSERT_INFOPOINT,
                      }),
                    ]).then(() => dispatch(loadInfoPoints()))
                  } else {
                    dispatch(uploadLoading(false))
                    dispatch(
                      showMessage({
                        message: 'Failed to update uploaded data',
                        autoHideDuration: 2000,
                        anchorOrigin: {
                          vertical: 'top',
                          horizontal: 'right',
                        },
                        variant: 'error',
                      }),
                    )
                  }
                })
            })
            .catch((err) => {
              console.log('Failed to upload infopoint image to S3: ', err)
              dispatch(uploadLoading(false))
              dispatch(
                showMessage({
                  message:
                    'Failed to upload infopoint image to S3: ' + err.message,
                  autoHideDuration: 2000,
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                  },
                  variant: 'error',
                }),
              )
            })
        } else {
          dispatch(
            showMessage({
              message: 'Failed to insert info point',
              autoHideDuration: 2000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'fail',
            }),
          )
        }
      })
  }
}

export const UPDATE_INFOPOINT = '[INFOPOINTS] UPDATE INFOPOINT'
export function updateInfoPoint(id, updateData) {
  console.log('updateInfoPoint updateData', updateData)
  return (dispatch) => {
    const { mainbg_id, mainbg_guid, title, description } = updateData
    axios
      .patch(`${API_SERVER_URL}/api/infopoints/${id}`, {
        title,
        description,
        image: jsonArray([updateData.image[0]]),
      })
      .then(({ data: updateResponse }) => {
        console.log('updateInfoPoint updateResponse', updateResponse)
        if (updateResponse.success) {
          dispatch(uploadLoading(true))
          putFiles(
            updateData.image.filter((file) => file.originFileObj !== undefined),
            `uploads/infopoints/${mainbg_guid}_${mainbg_id}/`,
          )
            .then((image) => {
              axios
                .post(`${API_SERVER_URL}/api/base/updateUploadData`, {
                  id: updateResponse.value[0].id,
                  tableName: 'info_points',
                  data: [
                    {
                      fieldName: 'image',
                      type: 'single',
                      data: image,
                    },
                  ],
                })
                .then((uploadResponse) => {
                  const { success } = uploadResponse.data
                  dispatch(uploadLoading(false))
                  if (success === true) {
                    dispatch(
                      showMessage({
                        message: 'Successfully updated',
                        autoHideDuration: 2000,
                        anchorOrigin: {
                          vertical: 'top',
                          horizontal: 'right',
                        },
                        variant: 'success',
                      }),
                    )
                    Promise.all([
                      dispatch({
                        type: UPDATE_INFOPOINT,
                      }),
                    ]).then(() => dispatch(loadInfoPoints()))
                  } else {
                    console.log('failed to updateUploadData')
                  }
                })
            })
            .catch((err) => {
              dispatch(uploadLoading(false))
              console.log('failed to upload infopoint image. ', err)
              return
            })
        } else {
          dispatch(
            showMessage({
              message: 'Failed to update info point',
              autoHideDuration: 2000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'fail',
            }),
          )
        }
      })
  }
}

export const DELETE_INFOPOINT = '[INFOPOINTS] DELETE INFOPOINT'
export function deleteInfoPoint(id, image) {
  console.log('deleteInfoPoint', id, image)
  return (dispatch) => {
    axios
      .delete(`${API_SERVER_URL}/api/infopoints/${id}`)
      .then(({ data: deleteResponse }) => {
        console.log('deleteInfoPoint deleteResponse', deleteResponse)
        if (deleteResponse.success) {
          dispatch(uploadLoading(true))
          deleteFiles([image])
            .then((res) => {
              dispatch(uploadLoading(false))
              dispatch(
                showMessage({
                  message: 'Successfully Deleted',
                  autoHideDuration: 2000,
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                  },
                  variant: 'success',
                }),
              )
            })
            .catch((err) => {
              dispatch(uploadLoading(false))
              console.log('failed to delete infopoint image. ', err)
              dispatch(
                showMessage({
                  message: 'AWS S3 DELETE INFO POINT ERROR',
                  autoHideDuration: 4000,
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                  },
                  variant: 'error',
                }),
              )
              return
            })
            .finally(() => {
              Promise.all([
                dispatch({
                  type: DELETE_INFOPOINT,
                }),
              ]).then(() => dispatch(loadInfoPoints()))
            })

          // Promise.all([
          //   dispatch(
          //     showMessage({
          //       message: "Successfully Deleted",
          //       autoHideDuration: 2000,
          //       anchorOrigin: {
          //         vertical: "top",
          //         horizontal: "right",
          //       },
          //       variant: "success",
          //     })
          //   ),
          //   dispatch({
          //     type: DELETE_INFOPOINT,
          //   }),
          // ]).then(() => dispatch(loadInfoPoints()));
        } else {
          dispatch(
            showMessage({
              message: 'Failed to delete info point',
              autoHideDuration: 2000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'fail',
            }),
          )
        }
      })
  }
}
