import React, { Component } from "react";
import * as Actions from "./store/actions";
import reducer from "./store/reducers";
import { withRouter } from "react-router-dom";
import withReducer from "app/store/withReducer";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FusePageCarded, FuseAnimate } from "@fuse";
import { IconButton, Button, Drawer, Divider } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { withStyles } from "@material-ui/core/styles";
import * as appActions from "app/store/actions";
import {
  Table,
  Typography,
  Select,
  Input,
  Spin,
  Upload,
  Form,
  Modal,
  Row,
  Col,
  Tooltip,
  Icon,
  Button as AntDButton
} from "antd";
import Highlighter from "react-highlight-words";
import { CABINET_DATA, DOORSTYLE_TYPE_DATA } from "../../main/config";

const { Title } = Typography;
const { Option } = Select;
const { confirm } = Modal;
const FormItem = Form.Item;

const formItemLayout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 12 }
};

const defaultPagination = {
  pageSizeOptions: ["10", "20", "50", "70", "100", "200", "500"],
  showSizeChanger: true,
  size: "small",
  position: "top"
};

const styles = theme => ({
  button: {
    marginTop: "-12px"
  },
  input: {
    display: "none"
  },
  list: {
    width: 500
  }
});

class PCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      type: "new",
      id: null,
      fieldData: {
        category_name: undefined,
        parent_category_name: "",
        doorstyle_type: "",
        texture_url: []
      },
      searchText: ""
    };
    props.getPCategories();
  }

  toggleDrawer = (type, record, open) => () => {
    if (type === "new") {
      this.setState({
        fieldData: {
          category_name: undefined,
          parent_category_name: "",
          doorstyle_type: '',
          texture_url: []
        },
        id: null
      });
    } else if (type === "edit") {
      this.setState({
        id: record.id,
        guid: record.guid,
        category_name: record.category_name,
        fieldData: {
          category_name: record.category_name,
          parent_category_name: record.parent_category_name,
          doorstyle_type: record.doorstyle_type,
          texture_url: [
            {
              uid: 1,
              name: record.texture_url.split("/").pop(),
              status: "done",
              url: record.texture_url
            }
          ]
        }
      });
    }
    this.setState({
      type: type,
      visible: open
    });
  };

  handleSubmit = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { type, id } = this.state;
        this.setState({ visible: false });
        let tmp = {...values};
        if(!values.texture_url){
          tmp = {...tmp, texture_url: []}
        }
        if (type === "new") {
          this.props.addPCategories(tmp);
        }
        if (type === "edit" && id !== null) {
          this.props.updatePCategories(tmp, id, this.state.guid);
        }
      }
    });
  };

  handleDeleteEvent = record => {
    const { deletePCategories } = this.props;
    confirm({
      title: "Do you want to delete this Item?",
      onOk() {
        deletePCategories(record);
      },
      onCancel() {}
    });
  };

  normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  moveAction = (type, record) => () => {
    const { pcategories } = this.props;
    const index = pcategories.findIndex(el => el.id === record.id);
    let swapIndex = -1;
    if (
      type === "up" &&
      index !== 0 &&
      pcategories[index - 1].category_name === record.category_name
    ) {
      swapIndex = index - 1;
    } else if (
      type === "down" &&
      index !== pcategories.length - 1 &&
      pcategories[index + 1].category_name === record.category_name
    ) {
      swapIndex = index + 1;
    }
    if (swapIndex !== -1) {
      this.props.setSortOrder({
        id: pcategories[index].id,
        sort_order: pcategories[swapIndex].sort_order
      });
      this.props.setSortOrder({
        id: pcategories[swapIndex].id,
        sort_order: pcategories[index].sort_order
      });
    }
  };

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={"search..."}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <AntDButton
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </AntDButton>
        <AntDButton
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </AntDButton>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex] &&
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text => {
      return (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text === null ? "" : text.toString()}
        />
      )      
    }
  });

  render() {
    const { classes, pcategories, isLoading } = this.props;
    const { visible, type, fieldData } = this.state;
    const {
      getFieldDecorator,
      setFieldsValue,
      getFieldValue
    } = this.props.form;
    let typeItems = [];
    CABINET_DATA.forEach(fElement => {
      typeItems.push(
        <Option key={fElement.id} value={fElement.value}>
          {fElement.value}
        </Option>
      );
    });
    let styleTypeItems = [];
    styleTypeItems.push(<Option key={-1} value={''}>None</Option>);
    DOORSTYLE_TYPE_DATA.forEach(fElement => {
      styleTypeItems.push(
        <Option key={fElement.id} value={fElement.value}>
          {fElement.value}
        </Option>
      );
    });
    const uploadButton = (
      <div>
        <AddIcon />
        <div className="ant-upload-text">
          {type === "new" ? "Upload" : "Change & Upload"}
        </div>
      </div>
    );
    const columns = [
      {
        key: "parent_category_name",
        title: "Parent Category",
        dataIndex: "parent_category_name",
        width: 200,
        ...this.getColumnSearchProps("parent_category_name")
      },
      {
        key: "category_name",
        title: "Category",
        dataIndex: "category_name",
        width: 200,
        ...this.getColumnSearchProps("category_name")
      },
      {
        key: "doorstyle_type",
        title: "DoorStyle Type",
        dataIndex: "doorstyle_type",
        width: 200,
        ...this.getColumnSearchProps("doorstyle_type")
      },
      {
        key: "texture_url",
        title: "Menu Icon",
        dataIndex: "texture_url",
        colSpan: 2,
        width: 400,
        render: (text, record) => (
          <Tooltip placement="topLeft" title={text}>
            <span>{text.slice(0, 40) + "..."}</span>
          </Tooltip>
        )
      },
      {
        key: "textureThumbnailPreview",
        dataIndex: "texture_url",
        colSpan: 0,
        width: 150,
        render: (text, record) => (
          <Row className="list__thumbnail">
            <img src={text} alt="" />
          </Row>
        )
      },
      {
        key: "action",
        title: "Action",
        dataIndex: "id",
        width: 150,
        render: (text, record) => (
          <Row>
            <IconButton
              className={classes.button}
              aria-label="ArrowUp"
              onClick={this.moveAction("up", record)}
            >
              <ArrowUpwardIcon />
            </IconButton>
            <IconButton
              className={classes.button}
              aria-label="ArrowDown"
              onClick={this.moveAction("down", record)}
            >
              <ArrowDownwardIcon />
            </IconButton>
            <IconButton
              className={classes.button}
              aria-label="Delete"
              onClick={this.toggleDrawer("edit", record, true)}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              className={classes.button}
              aria-label="Edit"
              onClick={e => {
                e.preventDefault();
                this.handleDeleteEvent(record);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Row>
        )
      }
    ];

    return (
      <Spin tip="Loading..." spinning={isLoading}>
        <FusePageCarded
          classes={{
            toolbar: "p-0",
            header: "min-h-72 h-72 sm:h-136 sm:min-h-136"
          }}
          header={
            <div className="flex flex-1 w-full items-center justify-between">
              <div className="flex flex-col items-start max-w-full">
                <div className="flex items-center max-w-full">
                  <div className="flex flex-col min-w-0">
                    <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                      <Title
                        level={4}
                        className="text-16 sm:text-20 truncate"
                      />
                    </FuseAnimate>
                  </div>
                </div>
              </div>
              <FuseAnimate animation="transition.slideRightIn" delay={300}>
                <Button
                  className="whitespace-no-wrap"
                  variant="contained"
                  onClick={this.toggleDrawer("new", null, true)}
                >
                  Add
                </Button>
              </FuseAnimate>
            </div>
          }
          contentToolbar={
            <Title
              level={4}
              style={{ marginLeft: "25px" }}
              className="text-16 sm:text-20 truncate"
              classes={{ root: "w-full h-64" }}
            >
              {"Shape"}
            </Title>
          }
          content={
            <div className="p-24">
              <Row>
                <Drawer
                  anchor="right"
                  open={visible}
                  variant="temporary"
                  onClose={this.toggleDrawer(null, null, false)}
                  classes={{ paper: classes.list }}
                  onRendered={() => {
                    setFieldsValue({ ...this.state.fieldData });
                  }}
                >
                  <div tabIndex={0} role="button">
                    <Title
                      level={3}
                      className="pt-16"
                      style={{ textAlign: "center" }}
                    >
                      {type === "new" ? "Add" : "Edit"} Molding Categories
                    </Title>
                    <Divider />
                    <Form layout="vertical" style={{ marginTop: "20px" }}>
                      <Row gutter={8}>
                        <Col span={2} />
                        <Col span={22}>
                          <FormItem
                            key="category_name"
                            {...formItemLayout}
                            label="Categroy"
                          >
                            {getFieldDecorator("category_name", {
                              initialValue: fieldData.category_name,
                              validateTrigger: "onSelect",
                              rules: [
                                {
                                  required: true,
                                  message: "Please select Categroy!"
                                }
                              ]
                            })(
                              <Select
                                dropdownStyle={{
                                  zIndex: 10000,
                                  maxHeight: 400,
                                  overflow: "auto"
                                }}
                                placeholder="Please select Categroy"
                                treeDefaultExpandAll
                                onSelect={value => {
                                  this.setState({
                                    fieldData: {
                                      ...fieldData,
                                      category_name: value
                                    }
                                  });
                                }}
                              >
                                {typeItems}
                              </Select>
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col span={2} />
                        <Col span={22}>
                          <FormItem
                            key="parent_category_name"
                            {...formItemLayout}
                            label="Parent Category"
                          >
                            {getFieldDecorator("parent_category_name", {
                              initialValue: fieldData.parent_category_name,
                              rules: [
                                {
                                  required: true,
                                  message: "Enter Parent Category!"
                                }
                              ]
                            })(
                              <Input
                                placeholder="Enter Parent Category"
                                onChange={e => {
                                  this.setState({
                                    fieldData: {
                                      ...fieldData,
                                      parent_category_name: e.target.value
                                    }
                                  });
                                }}
                              />
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                      {
                        fieldData.category_name && fieldData.category_name.includes('Cabinets') ? 
                        <Row gutter={8}>
                          <Col span={2} />
                          <Col span={22}>
                            <FormItem
                              key="doorstyle_type"
                              {...formItemLayout}
                              label="DoorStyleType"
                            >
                              {getFieldDecorator("doorstyle_type", {
                                initialValue: fieldData.doorstyle_type,
                                validateTrigger: "onSelect",
                              })(
                                <Select
                                  dropdownStyle={{
                                    zIndex: 10000,
                                    maxHeight: 400,
                                    overflow: "auto"
                                  }}
                                  placeholder="Please select DoorStyle Type"
                                  treeDefaultExpandAll
                                  onSelect={value => {
                                    this.setState({
                                      fieldData: {
                                        ...fieldData,
                                        doorstyle_type: value
                                      }
                                    });
                                  }}
                                >
                                  {styleTypeItems}
                                </Select>
                              )}
                            </FormItem>
                          </Col>
                        </Row> : null
                      }
                      {
                        fieldData.category_name && fieldData.category_name.includes('Cabinets') ? 
                          <Row gutter={8}>
                            <Col span={2} />
                            <Col span={22}>
                              <FormItem
                                key="texture_url"
                                {...formItemLayout}
                                label="Menu Icon"
                              >
                                {getFieldDecorator("texture_url", {
                                  initialValue: fieldData.texture_url,
                                  valuePropName: "fileList",
                                  getValueFromEvent: this.normFile,
                                  rules: [
                                    {
                                      required: true,
                                      message: "Please upload Thumbnail!"
                                    }
                                  ]
                                })(
                                  <Upload
                                    listType="picture-card"
                                    className="upload-list-inline"
                                    onRemove={file => {
                                      this.setState({
                                        fieldData: { ...fieldData, texture_url: [] }
                                      });
                                    }}
                                    beforeUpload={file => {
                                      this.setState({
                                        fieldData: {
                                          ...fieldData,
                                          texture_url: [file]
                                        }
                                      });
                                      return false;
                                    }}
                                  >
                                    {getFieldValue("texture_url").length > 0
                                      ? null
                                      : uploadButton}
                                  </Upload>
                                )}
                              </FormItem>
                            </Col>
                          </Row> : null
                      }
                    </Form>
                    <div
                      style={{
                        position: "absolute",
                        left: 0,
                        bottom: 0,
                        width: "100%",
                        borderTop: "1px solid #e9e9e9",
                        padding: "10px 16px",
                        background: "#fff",
                        textAlign: "right"
                      }}
                    >
                      <Button
                        onClick={this.toggleDrawer(null, null, false)}
                        style={{ marginRight: 8 }}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={this.handleSubmit}
                        variant="contained"
                        color="primary"
                        // disabled={!this.canBeSubmitted()}
                      >
                        {type === "new" ? "ADD" : "UPDATE"}
                      </Button>
                    </div>
                  </div>
                </Drawer>
                <Table
                  bordered
                  rowKey="id"
                  className="mtable"
                  pagination={defaultPagination}
                  columns={columns}
                  dataSource={pcategories}
                  scroll={{ x: 1400, y: 500 }}
                />
              </Row>
            </div>
          }
        />
      </Spin>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getPCategories: Actions.getPCategories,
      addPCategories: Actions.addPCategories,
      deletePCategories: Actions.deletePCategories,
      updatePCategories: Actions.updatePCategories,
      showMessage: appActions.showMessage,
      setSortOrder: Actions.setSortOrder
    },
    dispatch
  );
}

function mapStateToProps({ pcategories, fuse }) {
  return {
    pcategories: pcategories.pcategories.pcategories,
    isLoading: fuse.loading.isLoading
  };
}
const PCategoriesComponent = Form.create()(PCategories);

export default withReducer("pcategories", reducer)(
  withStyles(styles, { withTheme: true })(
    withRouter(
      connect(
        mapStateToProps,
        mapDispatchToProps
      )(PCategoriesComponent)
    )
  )
);
