import * as Actions from "../actions";

const initialState = {
  color: [],
  colorFamily: []
};

const shapesReducer = function(state = initialState, action) {
  switch (action.type) {
    case Actions.GET_COLOR: {
      return {
        ...state,
        ...action
      };
    }
    case Actions.GET_COLOR_FAMILY: {
      return {
        ...state,
        ...action
      };
    }
    default: {
      return state;
    }
  }
};

export default shapesReducer;
