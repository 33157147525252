import React, { Component } from 'react'
import * as Actions from './store/actions'
import reducer from './store/reducers'
import { withRouter } from 'react-router-dom'
import withReducer from 'app/store/withReducer'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FusePageCarded, FuseAnimate } from '@fuse'
import { IconButton, Button, Drawer, Divider } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import { withStyles } from '@material-ui/core/styles'
import * as appActions from 'app/store/actions'
import {
  Table,
  Typography,
  Select,
  Input,
  Spin,
  Upload,
  Form,
  Modal,
  Row,
  Col,
  Tooltip,
} from 'antd'
import { ChromePicker } from 'react-color'

const { Option } = Select
const { Title } = Typography
const { confirm } = Modal
const FormItem = Form.Item

const formItemLayout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 12 },
}

const defaultPagination = {
  pageSizeOptions: ['10', '20', '50', '70', '100', '200', '500'],
  showSizeChanger: true,
  size: 'small',
  position: 'top',
}

const styles = (theme) => ({
  button: {
    marginTop: '-12px',
  },
  input: {
    display: 'none',
  },
  list: {
    width: 500,
  },
})

class Color extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      type: 'new',
      id: null,
      guid: null,
      name: '',
      color_family_id: null,
      thumbnail: [],
      rgb: null,
    }
    props.getColor()
    props.getColorFamily()
  }

  toggleDrawer = (type, record, open) => () => {
    if (type === 'new') {
      this.setState({
        type: type,
        id: null,
        guid: null,
        color_family_id: null,
        name: '',
        thumbnail: [],
        rgb: null,
      })
    } else if (type === 'edit') {
      this.setState({
        type: type,
        id: record.id,
        guid: record.guid,
        color_family_id: record.color_family_id,
        name: record.name,
        thumbnail: [
          {
            uid: 1,
            name: record.thumbnail.split('/').pop(),
            status: 'done',
            url: record.thumbnail,
          },
        ],
        rgb: JSON.parse(record.rgb),
      })
    }
    this.setState({
      visible: open,
    })
  }

  handleSubmit = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { type, id } = this.state
        this.setState({ visible: false })
        if (type === 'new') {
          this.props.addColor(values)
        }
        if (type === 'edit' && id !== null) {
          this.props.updateColor(values, id, this.state.guid)
        }
      }
    })
  }

  handleDeleteEvent = (record) => {
    const { deleteColor } = this.props
    confirm({
      title: 'Do you want to delete this Item?',
      onOk() {
        deleteColor(record)
      },
      onCancel() {},
    })
  }

  normFile = (e) => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  moveAction = (type, record) => () => {
    const { color } = this.props
    const index = color.findIndex((el) => el.id === record.id)
    let swapIndex = -1
    if (type === 'up' && index !== 0) {
      swapIndex = index - 1
    } else if (type === 'down' && index !== color.length - 1) {
      swapIndex = index + 1
    }
    if (swapIndex !== -1) {
      this.props.setSortOrder({
        id: color[index].id,
        sort_order: color[swapIndex].sort_order,
      })
      this.props.setSortOrder({
        id: color[swapIndex].id,
        sort_order: color[index].sort_order,
      })
    }
  }

  render() {
    const { classes, color, colorFamily, isLoading } = this.props
    const { name, thumbnail, color_family_id, visible, type, rgb } = this.state
    const { getFieldDecorator, setFieldsValue } = this.props.form
    const uploadButton = (
      <div>
        <AddIcon />
        <div className="ant-upload-text">
          {type === 'new' ? 'Upload' : 'Change & Upload'}
        </div>
      </div>
    )

    const familyItems = []
    colorFamily.forEach((fElement) => {
      familyItems.push(
        <Option key={fElement.id} value={fElement.id}>
          {fElement.name}
        </Option>,
      )
    })

    const columns = [
      {
        key: 'name',
        title: 'Name',
        dataIndex: 'name',
        width: 350,
      },
      {
        key: 'rgb',
        title: 'RGB Color',
        dataIndex: 'rgb',
        width: 100,
        render: (text, record) => (
          <Row className="list__thumbnail">
            <div
              style={{
                background: record.rgb
                  ? `rgb(${JSON.parse(record.rgb).r}, ${
                      JSON.parse(record.rgb).g
                    }, ${JSON.parse(record.rgb).b}, ${
                      JSON.parse(record.rgb).a
                    })`
                  : 'transparent',
                width: '100%',
                height: 30,
              }}
            />
          </Row>
        ),
      },
      {
        key: 'color_family_name',
        title: 'Color Family',
        dataIndex: 'color_family_name',
        width: 350,
      },
      {
        key: 'thumbnail',
        title: 'Thumbnail',
        dataIndex: 'thumbnail',
        colSpan: 2,
        width: 400,
        render: (text, record) => (
          <Tooltip placement="topLeft" title={text}>
            <span>{text}</span>
          </Tooltip>
        ),
      },
      {
        key: 'preview',
        dataIndex: 'thumbnail',
        colSpan: 0,
        width: 150,
        render: (text, record) => (
          <Row className="list__thumbnail">
            <img src={text} alt="" />
          </Row>
        ),
      },
      {
        key: 'action',
        title: 'Action',
        dataIndex: 'id',
        width: 250,
        fixed: 'right',
        render: (text, record) => (
          <Row>
            <IconButton
              className={classes.button}
              aria-label="ArrowUp"
              onClick={this.moveAction('up', record)}
            >
              <ArrowUpwardIcon />
            </IconButton>
            <IconButton
              className={classes.button}
              aria-label="ArrowDown"
              onClick={this.moveAction('down', record)}
            >
              <ArrowDownwardIcon />
            </IconButton>
            <IconButton
              className={classes.button}
              aria-label="Delete"
              onClick={this.toggleDrawer('edit', record, true)}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              className={classes.button}
              aria-label="Edit"
              onClick={(e) => {
                e.preventDefault()
                this.handleDeleteEvent(record)
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Row>
        ),
      },
    ]

    return (
      <Spin tip="Loading..." spinning={isLoading}>
        <FusePageCarded
          classes={{
            toolbar: 'p-0',
            header: 'min-h-72 h-72 sm:h-136 sm:min-h-136',
          }}
          header={
            <div className="flex flex-1 w-full items-center justify-between">
              <div className="flex flex-col items-start max-w-full">
                <div className="flex items-center max-w-full">
                  <div className="flex flex-col min-w-0">
                    <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                      <Title
                        level={4}
                        className="text-16 sm:text-20 truncate"
                      />
                    </FuseAnimate>
                  </div>
                </div>
              </div>
              <FuseAnimate animation="transition.slideRightIn" delay={300}>
                <Button
                  className="whitespace-no-wrap"
                  variant="contained"
                  onClick={this.toggleDrawer('new', null, true)}
                >
                  Add
                </Button>
              </FuseAnimate>
            </div>
          }
          contentToolbar={
            <Title
              level={4}
              style={{ marginLeft: '25px' }}
              className="text-16 sm:text-20 truncate"
              classes={{ root: 'w-full h-64' }}
            >
              {'Color'}
            </Title>
          }
          content={
            <div className="p-24">
              <Row>
                <Drawer
                  anchor="right"
                  open={visible}
                  variant="temporary"
                  onClose={this.toggleDrawer(null, null, false)}
                  classes={{ paper: classes.list }}
                  onRendered={() => {
                    var data = {
                      name: this.state.name,
                      color_family_id: this.state.color_family_id,
                      thumbnail: this.state.thumbnail,
                    }
                    setFieldsValue({ ...data })
                  }}
                >
                  <div tabIndex={0} role="button">
                    <Title
                      level={3}
                      className="pt-16"
                      style={{ textAlign: 'center' }}
                    >
                      {type === 'new' ? 'Add' : 'Edit'} Color
                    </Title>
                    <Divider />
                    <Form layout="vertical" style={{ marginTop: '20px' }}>
                      <Row gutter={8}>
                        <Col span={2} />
                        <Col span={22}>
                          <FormItem
                            key="name"
                            {...formItemLayout}
                            label="Color Name"
                          >
                            {getFieldDecorator('name', {
                              initialValue: name,
                              rules: [
                                {
                                  required: true,
                                  message: 'Enter Color Name.',
                                },
                              ],
                            })(
                              <Input
                                placeholder="Enter Color Name"
                                onChange={(e) => {
                                  this.setState({ name: e.target.value })
                                }}
                              />,
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col span={2} />
                        <Col span={22}>
                          <FormItem
                            key="color_family_id"
                            {...formItemLayout}
                            label="Color Family"
                          >
                            {getFieldDecorator('color_family_id', {
                              initialValue: color_family_id,
                              rules: [
                                {
                                  required: true,
                                  message: 'Please select your Color Family!',
                                },
                              ],
                            })(
                              <Select
                                dropdownStyle={{
                                  zIndex: 10000,
                                  maxHeight: 400,
                                  overflow: 'auto',
                                }}
                                placeholder="Please select your Color Family"
                                onChange={(value) => {
                                  this.setState({
                                    color_family_id: value,
                                  })
                                }}
                              >
                                {familyItems}
                              </Select>,
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col span={2} />
                        <Col span={22}>
                          <FormItem
                            key="thumbnail"
                            {...formItemLayout}
                            label="Thumbnail"
                          >
                            {getFieldDecorator('thumbnail', {
                              initialValue: thumbnail,
                              valuePropName: 'fileList',
                              rules: [
                                {
                                  required: true,
                                  message: 'Please select thumbnail image!',
                                },
                              ],
                              getValueFromEvent: this.normFile,
                            })(
                              <Upload
                                listType="picture-card"
                                className="upload-list-inline"
                                onRemove={(file) => {
                                  this.setState({
                                    thumbnail: [],
                                  })
                                }}
                                beforeUpload={(file) => {
                                  this.setState({
                                    thumbnail: [file],
                                  })
                                  return false
                                }}
                              >
                                {thumbnail.length > 0 ? null : uploadButton}
                              </Upload>,
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col span={2} />
                        <Col span={22}>
                          <FormItem
                            key="rgb"
                            {...formItemLayout}
                            label="RGB Color"
                          >
                            {getFieldDecorator('rgb', {
                              initialValue: rgb,
                              rules: [
                                {
                                  required: true,
                                  message: 'Enter RGB Color.',
                                },
                              ],
                              getValueFromEvent: (e) => e.rgb,
                            })(
                              <ChromePicker
                                color={rgb || false}
                                value={rgb}
                                width={'100%'}
                                defaultView={'rgb'}
                                styles={{
                                  default: {
                                    picker: {
                                      background: 'transparent',
                                      boxShadow: 'none',
                                      borderRadius: 0,
                                    },
                                    saturation: {
                                      borderRadius: '8px',
                                      paddingBottom: '240px',
                                      background: 'transparent',
                                    },
                                    Saturation: {
                                      radius: '12px 12px 0 0',
                                    },
                                    body: {
                                      background: 'transparent',
                                      padding: 0,
                                      paddingTop: 15,
                                    },
                                    color: {
                                      width: 0,
                                    },
                                    swatch: {
                                      display: 'none',
                                    },
                                    hue: {
                                      height: 20,
                                    },
                                    alpha: {
                                      height: 20,
                                    },
                                  },
                                }}
                                onChangeComplete={(color) => {
                                  this.setState({ rgb: color.rgb })
                                }}
                                onChange={(color) => {
                                  this.setState({ rgb: color.rgb })
                                }}
                              />,
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                    </Form>
                    <div
                      style={{
                        position: 'absolute',
                        left: 0,
                        bottom: 0,
                        width: '100%',
                        borderTop: '1px solid #e9e9e9',
                        padding: '10px 16px',
                        background: '#fff',
                        textAlign: 'right',
                      }}
                    >
                      <Button
                        onClick={this.toggleDrawer(null, null, false)}
                        style={{ marginRight: 8 }}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={this.handleSubmit}
                        variant="contained"
                        color="primary"
                        // disabled={!this.canBeSubmitted()}
                      >
                        {type === 'new' ? 'ADD' : 'UPDATE'}
                      </Button>
                    </div>
                  </div>
                </Drawer>
                <Table
                  bordered
                  rowKey="id"
                  className="mtable"
                  pagination={defaultPagination}
                  columns={columns}
                  dataSource={color}
                  scroll={{ x: 1500, y: 500 }}
                />
              </Row>
            </div>
          }
        />
      </Spin>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getColor: Actions.getColor,
      getColorFamily: Actions.getColorFamily,
      addColor: Actions.addColor,
      deleteColor: Actions.deleteColor,
      updateColor: Actions.updateColor,
      showMessage: appActions.showMessage,
      setSortOrder: Actions.setSortOrder,
    },
    dispatch,
  )
}

function mapStateToProps({ color, fuse }) {
  return {
    color: color.color.color,
    colorFamily: color.color.colorFamily,
    isLoading: fuse.loading.isLoading,
  }
}
const ColorComponent = Form.create()(Color)

export default withReducer(
  'color',
  reducer,
)(
  withStyles(styles, { withTheme: true })(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(ColorComponent)),
  ),
)
