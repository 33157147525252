import InfoPoints from "./InfoPoints.jsx";
import { authRoles } from "app/auth";

export const InfoPointsConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.designer,
  routes: [
    {
      path: "/infopoints",
      component: InfoPoints,
    },
  ],
};
