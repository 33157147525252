import React, { Component } from 'react'
import * as Actions from './store/actions'
import reducer from './store/reducers'
import { withRouter } from 'react-router-dom'
import withReducer from 'app/store/withReducer'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FusePageCarded, FuseAnimate } from '@fuse'
import { IconButton, Button, Drawer, Divider } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import { withStyles } from '@material-ui/core/styles'
import * as appActions from 'app/store/actions'
import * as shapeActions from '../shapes/store/actions'
import * as catalogActions from '../catalog/store/actions'
import {
  Table,
  Typography,
  Select,
  Input,
  Spin,
  Upload,
  Form,
  Modal,
  Row,
  Col,
  Tooltip,
  Checkbox,
} from 'antd'
import { DragDropContext, DragSource, DropTarget } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
let dragingIndex = -1
class BodyRow extends React.Component {
  render() {
    const {
      isOver,
      connectDragSource,
      connectDropTarget,
      moveRow,
      ...restProps
    } = this.props
    const style = { ...restProps.style, cursor: 'move' }
    let { className } = restProps
    if (isOver) {
      if (restProps.index > dragingIndex) {
        className += ' drop-over-downward'
      }
      if (restProps.index < dragingIndex) {
        className += ' drop-over-upward'
      }
    }
    return connectDragSource(
      connectDropTarget(
        <tr {...restProps} className={className} style={style} />,
      ),
    )
  }
}
const rowSource = {
  beginDrag(props) {
    dragingIndex = props['data-row-key']
    return {
      index: props['data-row-key'],
    }
  },
}
const rowTarget = {
  drop(props, monitor) {
    const dragIndex = monitor.getItem().index
    const hoverIndex = props['data-row-key']
    if (dragIndex === hoverIndex) {
      return
    }
    props.moveRow(dragIndex, hoverIndex)
    monitor.getItem().index = hoverIndex
  },
}
const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
}))(
  DragSource('row', rowSource, (connect) => ({
    connectDragSource: connect.dragSource(),
  }))(BodyRow),
)
// const { Option } = Select;
const { Title } = Typography
const { Option } = Select
const { confirm } = Modal
const FormItem = Form.Item

const formItemLayout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 12 },
}

const defaultPagination = {
  pageSizeOptions: ['10', '20', '50', '70', '100', '200', '500'],
  showSizeChanger: true,
  size: 'small',
  position: 'top',
}

const styles = (theme) => ({
  button: {
    marginTop: '-12px',
  },
  input: {
    display: 'none',
  },
  list: {
    width: 500,
  },
})

const typeLabels = {}

class MainBackground extends Component {
  constructor(props) {
    super(props)
    this.state = {
      main_background_name: '',
      catalog_id: undefined,
      shape_category_id: undefined,
      type_category_id: undefined,
      thumbnail: [],
      thumbnailsm: [],
      wall_mask_layer: [],
      occlusion_layer: [],
      room_flag: false,
      visible: false,
      type: 'new',
      id: null,
      isKitchen: false,
    }
    props.getMainBackground()
    props.getShapeCategory()
    props.getTypeCategory()
    props.getCatalogData()
  }
  components = {
    body: {
      row: DragableBodyRow,
    },
  }

  moveRow = (dragIndex, hoverIndex) => {
    const { mainbackgroundData } = this.props
    dragIndex = mainbackgroundData.findIndex((el) => el.id === dragIndex)
    hoverIndex = mainbackgroundData.findIndex((el) => el.id === hoverIndex)
    if (hoverIndex < dragIndex) {
      this.props.setSortOrder({
        id: mainbackgroundData[dragIndex].id,
        sort_order: hoverIndex,
      })
      for (let rowIndex = hoverIndex; rowIndex < dragIndex; rowIndex++) {
        this.props.setSortOrder({
          id: mainbackgroundData[rowIndex].id,
          sort_order: rowIndex + 1,
        })
      }
    } else {
      this.props.setSortOrder({
        id: mainbackgroundData[dragIndex].id,
        sort_order: hoverIndex,
      })
      for (
        let rowIndex = dragIndex + 1;
        rowIndex < hoverIndex + 1;
        rowIndex++
      ) {
        this.props.setSortOrder({
          id: mainbackgroundData[rowIndex].id,
          sort_order: rowIndex - 1,
        })
      }
    }
  }

  toggleDrawer = (type, record, open) => () => {
    if (type === 'new') {
      this.setState({
        main_background_name: '',
        catalog_id: undefined,
        shape_category_id: undefined,
        type_category_id: undefined,
        type: type,
        id: null,
        thumbnail: [],
        thumbnailsm: [],
        wall_mask_layer: [],
        occlusion_layer: [],
        room_flag: false,
      })
    } else if (type === 'edit') {
      if (record.isdirect === 0) this.setState({ isKitchen: true })
      this.setState({
        id: record.id,
        guid: record.guid,
        main_background_name: record.main_background_name,
        catalog_id: record.catalog_id,
        room_flag: !!record.room_flag,
        shape_category_id: record.main_background_name
          ? record.shape_category_id
          : '',
        type_category_id: record.main_background_name
          ? record.type_category_id
          : '',
        type: type,
        isdirect: record.isdirect,
        thumbnail: record.thumbnail
          ? [
              {
                uid: 1,
                name: record.thumbnail.split('/').pop(),
                status: 'done',
                url: record.thumbnail,
              },
            ]
          : [],
        thumbnailsm: record.thumbnailsm
          ? [
              {
                uid: 1,
                name: record.thumbnailsm.split('/').pop(),
                status: 'done',
                url: record.thumbnailsm,
              },
            ]
          : [],
        wall_mask_layer: record.wall_mask_layer
          ? [
              {
                uid: 1,
                name: record.wall_mask_layer.split('/').pop(),
                status: 'done',
                url: record.wall_mask_layer,
              },
            ]
          : [],
        occlusion_layer: record.occlusion_layer
          ? [
              {
                uid: 1,
                name: record.occlusion_layer.split('/').pop(),
                status: 'done',
                url: record.occlusion_layer,
              },
            ]
          : [],
      })
    }
    if (open === false) this.setState({ isKitchen: false })
    this.setState({
      visible: open,
    })
  }

  // canBeSubmitted()
  // {
  //     const {door_style_name, thumbnail, type} = this.state;
  //     return type==="new" ? (door_style_name.length > 0 && thumbnail.length > 0) : (door_style_name.length > 0);
  // }

  handleSubmit = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { type, id } = this.state
        this.setState({ visible: false })
        if (type === 'new') {
          this.props.addMainBackground(values, this.state.type_category_id)
        }
        if (type === 'edit' && id !== null) {
          this.props.updateMainBackground(
            values,
            id,
            this.state.guid,
            this.state.type_category_id,
          )
        }
      }
    })
  }

  handleDeleteEvent = (record) => {
    const { deleteMainBackground } = this.props
    confirm({
      title: 'Do you want to delete this Item?',
      onOk() {
        deleteMainBackground(record)
      },
      onCancel() {},
    })
  }

  normFile = (e) => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  moveAction = (type, record) => () => {
    const { mainbackgroundData } = this.props
    const index = mainbackgroundData.findIndex((el) => el.id === record.id)
    let swapIndex = -1
    if (
      type === 'up' &&
      index !== 0 &&
      mainbackgroundData[index - 1].shape_category_id ===
        record.shape_category_id
    ) {
      swapIndex = index - 1
    } else if (
      type === 'down' &&
      index !== mainbackgroundData.length - 1 &&
      mainbackgroundData[index + 1].shape_category_id ===
        record.shape_category_id
    ) {
      swapIndex = index + 1
    }
    if (swapIndex !== -1) {
      this.props.setSortOrder({
        id: mainbackgroundData[index].id,
        sort_order: mainbackgroundData[swapIndex].sort_order,
      })
      this.props.setSortOrder({
        id: mainbackgroundData[swapIndex].id,
        sort_order: mainbackgroundData[index].sort_order,
      })
    }
  }

  checkRoom = () => {
    this.setState({
      room_flag: !this.state.room_flag,
    })
  }

  render() {
    const {
      classes,
      mainbackgroundData,
      shapeCategory,
      typeCategory,
      isLoading,
      catalog,
    } = this.props
    const {
      thumbnail,
      thumbnailsm,
      wall_mask_layer,
      occlusion_layer,
      room_flag,
      catalog_id,
      main_background_name,
      type_category_id,
      visible,
      type,
    } = this.state
    const { getFieldDecorator, setFieldsValue } = this.props.form
    const uploadButton = (
      <div>
        <AddIcon />
        <div className="ant-upload-text">
          {type === 'new' ? 'Upload' : 'Change & Upload'}
        </div>
      </div>
    )
    mainbackgroundData.forEach((fElement) => {
      if (fElement.catalog_name == null) fElement.catalog_name = ' '
      fElement.type_category_id = Number(fElement.type_category_id)
    })
    const catalogItems = []
    catalog.forEach((fElement) => {
      catalogItems.push({
        text: fElement.name,
        value: fElement.name,
      })
    })
    catalogItems.push({
      text: 'null',
      value: ' ',
    })

    const catalogOptions = []
    catalog.forEach((fElement) => {
      catalogOptions.push(
        <Option key={fElement.id} value={fElement.id}>
          {fElement.name}
        </Option>,
      )
    })

    const typeItems = []
    const typeByCatId = typeCategory.filter(
      (item) => item.catalog_id == catalog_id,
    )
    typeByCatId.length != 0 &&
      typeByCatId.forEach((fElement) => {
        typeItems.push(
          <Option key={fElement.id} value={fElement.id}>
            {fElement.name}
          </Option>,
        )
        typeLabels[`${fElement.id}`] = fElement.name
      })

    const shapeItems = []
    const shapeByTypeId = shapeCategory.filter(
      (item) => item.type_category_id == type_category_id,
    )
    shapeByTypeId.length != 0 &&
      shapeByTypeId.forEach((fElement) => {
        shapeItems.push(
          <Option key={fElement.id} value={fElement.id}>
            {fElement.shapes_name}
          </Option>,
        )
      })

    const columns = [
      {
        key: 'main_background_name',
        title: 'Name',
        dataIndex: 'main_background_name',
        width: 160,
      },
      {
        key: 'shapeCategory',
        title: 'Shape Category',
        dataIndex: 'shape_category_name',
        width: 150,
      },
      {
        key: 'type_category_name',
        title: 'Type Category',
        dataIndex: 'type_category_name',
        width: 150,
      },
      {
        key: 'catalog_name',
        title: 'Catalog',
        dataIndex: 'catalog_name',
        width: 150,
        filters: catalogItems,
        onFilter: (value, record) => record.catalog_name == value,
      },
      {
        key: 'thumbnail',
        title: 'Main Background',
        dataIndex: 'thumbnail',
        colSpan: 2,
        width: 400,
        render: (text, record) => (
          <Tooltip placement="topLeft" title={text}>
            <span>{text}</span>
          </Tooltip>
        ),
      },
      {
        key: 'preview',
        dataIndex: 'thumbnail',
        colSpan: 0,
        width: 150,
        render: (text, record) => (
          <Row className="list__thumbnail">
            <img src={text} alt="" style={{ objectFit: 'cover' }} />
          </Row>
        ),
      },
      {
        key: 'wall_mask_layer',
        title: 'Wall Mask Layer',
        dataIndex: 'wall_mask_layer',
        colSpan: 2,
        width: 400,
        render: (text, record) => (
          <Tooltip placement="topLeft" title={text}>
            <span>{text}</span>
          </Tooltip>
        ),
      },
      {
        key: 'wall_mask_layer_preview',
        dataIndex: 'wall_mask_layer',
        colSpan: 0,
        width: 150,
        render: (text, record) => (
          <Row className="list__thumbnail">
            <img src={text} alt="" style={{ objectFit: 'cover' }} />
          </Row>
        ),
      },
      {
        key: 'occlusion_layer',
        title: 'Occlusion Layer',
        dataIndex: 'occlusion_layer',
        colSpan: 2,
        width: 400,
        render: (text, record) => (
          <Tooltip placement="topLeft" title={text}>
            <span>{text}</span>
          </Tooltip>
        ),
      },
      {
        key: 'occlusion_layer_preview',
        dataIndex: 'occlusion_layer',
        colSpan: 0,
        width: 150,
        render: (text, record) => (
          <Row className="list__thumbnail">
            <img src={text} alt="" style={{ objectFit: 'cover' }} />
          </Row>
        ),
      },
      {
        key: 'action',
        title: 'Action',
        dataIndex: 'id',
        width: 250,
        fixed: 'right',
        render: (text, record) => (
          <Row>
            <IconButton
              className={classes.button}
              aria-label="ArrowUp"
              onClick={this.moveAction('up', record)}
            >
              <ArrowUpwardIcon />
            </IconButton>
            <IconButton
              className={classes.button}
              aria-label="ArrowDown"
              onClick={this.moveAction('down', record)}
            >
              <ArrowDownwardIcon />
            </IconButton>
            <IconButton
              className={classes.button}
              aria-label="Delete"
              onClick={this.toggleDrawer('edit', record, true)}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              className={classes.button}
              aria-label="Edit"
              onClick={(e) => {
                e.preventDefault()
                this.handleDeleteEvent(record)
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Row>
        ),
      },
    ]

    return (
      <Spin tip="Loading..." spinning={isLoading}>
        <FusePageCarded
          classes={{
            toolbar: 'p-0',
            header: 'min-h-72 h-72 sm:h-136 sm:min-h-136',
          }}
          header={
            <div className="flex flex-1 w-full items-center justify-between">
              <div className="flex flex-col items-start max-w-full">
                <div className="flex items-center max-w-full">
                  <div className="flex flex-col min-w-0">
                    <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                      <Title
                        level={4}
                        className="text-16 sm:text-20 truncate"
                      ></Title>
                    </FuseAnimate>
                  </div>
                </div>
              </div>
              <FuseAnimate animation="transition.slideRightIn" delay={300}>
                <Button
                  className="whitespace-no-wrap"
                  variant="contained"
                  onClick={this.toggleDrawer('new', null, true)}
                >
                  Add
                </Button>
              </FuseAnimate>
            </div>
          }
          contentToolbar={
            <Title
              level={4}
              style={{ marginLeft: '25px' }}
              className="text-16 sm:text-20 truncate"
              classes={{ root: 'w-full h-64' }}
            >
              {'3D Assets'}
            </Title>
          }
          content={
            <div className="p-24">
              <Row>
                <Drawer
                  anchor="right"
                  open={visible}
                  variant="temporary"
                  onClose={this.toggleDrawer(null, null, false)}
                  classes={{ paper: classes.list }}
                  onRendered={() => {
                    var data = {
                      main_background_name: this.state.main_background_name,
                      catalog_id: this.state.catalog_id,
                      type_category_id: this.state.type_category_id,
                      shape_category_id: this.state.shape_category_id,
                      thumbnail: this.state.thumbnail,
                      thumbnailsm: this.state.thumbnailsm,
                      wall_mask_layer: this.state.wall_mask_layer,
                      occlusion_layer: this.state.occlusion_layer,
                      room_flag: !!this.state.room_flag,
                      isKitchen:
                        typeLabels[`${type_category_id}`] === 'Kitchen'
                          ? true
                          : false,
                    }
                    setFieldsValue({ ...data })
                  }}
                >
                  <div tabIndex={0} role="button">
                    <Title
                      level={3}
                      className="pt-16"
                      style={{ textAlign: 'center' }}
                    >
                      {type === 'new' ? 'Add' : 'Edit'} Texture
                    </Title>
                    <Divider />
                    <Form layout="vertical" style={{ marginTop: '20px' }}>
                      <Row gutter={8}>
                        <Col span={2} />
                        <Col span={22}>
                          <FormItem
                            key="main_background_name"
                            {...formItemLayout}
                            label="Background Name"
                          >
                            {getFieldDecorator('main_background_name', {
                              rules: [
                                {
                                  required: true,
                                  message: 'Enter Background Name.',
                                },
                              ],
                            })(
                              <Input
                                placeholder="Enter Background Name"
                                onChange={(e) => {
                                  this.setState({
                                    main_background_name: e.target.value,
                                  })
                                }}
                              />,
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col span={2} />
                        <Col span={22}>
                          <FormItem
                            key="catalog_id"
                            {...formItemLayout}
                            label="Catalog Name"
                          >
                            {getFieldDecorator('catalog_id', {
                              validateTrigger: 'onSelect',
                              rules: [
                                {
                                  required: true,
                                  message: 'Please select your Catalog!',
                                },
                              ],
                            })(
                              <Select
                                showSearch
                                dropdownStyle={{
                                  zIndex: 10000,
                                  maxHeight: 400,
                                  overflow: 'auto',
                                }}
                                placeholder="Please select you catalog"
                                treeDefaultExpandAll
                                onSelect={(value) => {
                                  this.setState({ catalog_id: value })
                                  setFieldsValue({
                                    type_category_id: undefined,
                                  })
                                  setFieldsValue({
                                    shape_category_id: undefined,
                                  })
                                }}
                              >
                                {catalogOptions}
                              </Select>,
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col span={2} />
                        <Col span={22}>
                          <FormItem
                            key="type_category_id"
                            {...formItemLayout}
                            label="Type Name"
                          >
                            {getFieldDecorator('type_category_id', {
                              validateTrigger: 'onSelect',
                              rules: [
                                {
                                  required: true,
                                  message: 'Please select your Type Category!',
                                },
                              ],
                            })(
                              <Select
                                showSearch
                                dropdownStyle={{
                                  zIndex: 10000,
                                  maxHeight: 400,
                                  overflow: 'auto',
                                }}
                                placeholder="Please select you type"
                                treeDefaultExpandAll
                                onSelect={(value) => {
                                  this.setState({ type_category_id: value })
                                  setFieldsValue({
                                    shape_category_id: undefined,
                                  })
                                }}
                              >
                                {typeItems}
                              </Select>,
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col span={2} />
                        <Col span={22}>
                          <FormItem
                            key="shape_category_id"
                            {...formItemLayout}
                            label="Shape Name"
                          >
                            {getFieldDecorator('shape_category_id', {
                              validateTrigger: 'onSelect',
                              rules: [
                                {
                                  required: true,
                                  message: 'Please select your Shape Category!',
                                },
                              ],
                            })(
                              <Select
                                showSearch
                                dropdownStyle={{
                                  zIndex: 10000,
                                  maxHeight: 400,
                                  overflow: 'auto',
                                }}
                                placeholder="Please select you shape"
                                treeDefaultExpandAll
                                onSelect={(value) => {
                                  this.setState({ shape_category_id: value })
                                }}
                              >
                                {shapeItems}
                              </Select>,
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col span={2} />
                        <Col span={22}>
                          <FormItem
                            key="thumbnail"
                            {...formItemLayout}
                            label="Main Background"
                          >
                            {getFieldDecorator('thumbnail', {
                              initialValue: thumbnail,
                              valuePropName: 'fileList',
                              rules: [
                                {
                                  required: true,
                                  message: 'Please select Main Background!',
                                },
                              ],
                              getValueFromEvent: this.normFile,
                            })(
                              <Upload
                                listType="picture-card"
                                className="upload-list-inline"
                                onRemove={(file) => {
                                  this.setState({
                                    thumbnail: [],
                                  })
                                }}
                                beforeUpload={(file) => {
                                  this.setState({
                                    thumbnail: [file],
                                  })
                                  return false
                                }}
                              >
                                {thumbnail.length > 0 ? null : uploadButton}
                              </Upload>,
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col span={2} />
                        <Col span={22}>
                          <FormItem
                            key="room_flag"
                            {...formItemLayout}
                            label="360 Room"
                          >
                            {getFieldDecorator('room_flag', {
                              initialValue: !!room_flag,
                            })(
                              <Checkbox
                                defaultChecked={!!room_flag}
                                onClick={this.checkRoom}
                              />,
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col span={2} />
                        <Col span={22}>
                          <FormItem
                            key="thumbnailsm"
                            {...formItemLayout}
                            label="Thumbnail Menu"
                          >
                            {getFieldDecorator('thumbnailsm', {
                              initialValue: thumbnailsm,
                              valuePropName: 'fileList',
                              rules: [
                                {
                                  required: true,
                                  message: 'Please select Thumbnail!',
                                },
                              ],
                              getValueFromEvent: this.normFile,
                            })(
                              <Upload
                                listType="picture-card"
                                className="upload-list-inline"
                                onRemove={(file) => {
                                  this.setState({
                                    thumbnailsm: [],
                                  })
                                }}
                                beforeUpload={(file) => {
                                  this.setState({
                                    thumbnailsm: [file],
                                  })
                                  return false
                                }}
                              >
                                {thumbnailsm.length > 0 ? null : uploadButton}
                              </Upload>,
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col span={2} />
                        <Col span={22}>
                          <FormItem
                            key="wall_mask_layer"
                            {...formItemLayout}
                            label="Wall Mask Layer"
                          >
                            {getFieldDecorator('wall_mask_layer', {
                              initialValue: wall_mask_layer,
                              valuePropName: 'fileList',
                              getValueFromEvent: this.normFile,
                            })(
                              <Upload
                                listType="picture-card"
                                className="upload-list-inline"
                                onRemove={(file) => {
                                  this.setState({
                                    wall_mask_layer: [],
                                  })
                                }}
                                beforeUpload={(file) => {
                                  this.setState({
                                    wall_mask_layer: [file],
                                  })
                                  return false
                                }}
                              >
                                {wall_mask_layer.length > 0
                                  ? null
                                  : uploadButton}
                              </Upload>,
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col span={2} />
                        <Col span={22}>
                          <FormItem
                            key="occlusion_layer"
                            {...formItemLayout}
                            label="Occlusion Layer"
                          >
                            {getFieldDecorator('occlusion_layer', {
                              initialValue: occlusion_layer,
                              valuePropName: 'fileList',
                              getValueFromEvent: this.normFile,
                            })(
                              <Upload
                                listType="picture-card"
                                className="upload-list-inline"
                                onRemove={(file) => {
                                  this.setState({
                                    occlusion_layer: [],
                                  })
                                }}
                                beforeUpload={(file) => {
                                  this.setState({
                                    occlusion_layer: [file],
                                  })
                                  return false
                                }}
                              >
                                {occlusion_layer.length > 0
                                  ? null
                                  : uploadButton}
                              </Upload>,
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                    </Form>
                    <div
                      style={{
                        position: 'absolute',
                        left: 0,
                        bottom: 0,
                        width: '100%',
                        borderTop: '1px solid #e9e9e9',
                        padding: '10px 16px',
                        background: '#fff',
                        textAlign: 'right',
                      }}
                    >
                      <Button
                        onClick={this.toggleDrawer(null, null, false)}
                        style={{ marginRight: 8 }}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={this.handleSubmit}
                        variant="contained"
                        color="primary"
                        // disabled={!this.canBeSubmitted()}
                      >
                        {type === 'new' ? 'ADD' : 'UPDATE'}
                      </Button>
                    </div>
                  </div>
                </Drawer>
                <Table
                  bordered
                  rowKey="id"
                  className="mtable"
                  pagination={defaultPagination}
                  columns={columns}
                  dataSource={mainbackgroundData}
                  scroll={{ x: 1400, y: 500 }}
                  components={this.components}
                  onRow={(record, index) => ({
                    index,
                    moveRow: this.moveRow,
                  })}
                />
              </Row>
            </div>
          }
        />
      </Spin>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getMainBackground: Actions.getMainBackground,
      getShapeCategory: Actions.getShapeCategory,
      addMainBackground: Actions.addMainBackground,
      deleteMainBackground: Actions.deleteMainBackground,
      updateMainBackground: Actions.updateMainBackground,
      showMessage: appActions.showMessage,
      setSortOrder: Actions.setSortOrder,
      getTypeCategory: Actions.getTypeCategory,
      getCatalogData: Actions.getCatalogData,
    },
    dispatch,
  )
}

function mapStateToProps({ MainBackground, fuse }) {
  return {
    mainbackgroundData: MainBackground.mainbackground.mainbackground,
    shapeCategory: MainBackground.mainbackground.shapeCategory,
    isLoading: fuse.loading.isLoading,
    typeCategory: MainBackground.mainbackground.typeCategory,
    catalog: MainBackground.mainbackground.catalog,
  }
}
const MainBackgroundComponent = Form.create()(
  DragDropContext(HTML5Backend)(MainBackground),
)

export default withReducer(
  'MainBackground',
  reducer,
)(
  withStyles(styles, { withTheme: true })(
    withRouter(
      connect(mapStateToProps, mapDispatchToProps)(MainBackgroundComponent),
    ),
  ),
)
