import React, {Component} from 'react';
import * as Actions from './store/actions';
import reducer from './store/reducers';
import {withRouter} from 'react-router-dom';
import withReducer from 'app/store/withReducer';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {FusePageCarded, FuseAnimate} from '@fuse';
import {IconButton, Button, Drawer, Divider, Chip} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { withStyles } from '@material-ui/core/styles';
import * as appActions from 'app/store/actions';
import {Table, Typography, Input, Spin, Upload, Form, Modal, Row, Col, Tooltip, Checkbox, Select} from 'antd';
import { DragDropContext, DragSource, DropTarget } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import axios from 'axios';
import { API_SERVER_URL } from 'app/main/config';

let dragingIndex = -1;
class BodyRow extends React.Component {
    render() {
        const { isOver, connectDragSource, connectDropTarget, moveRow, ...restProps } = this.props;
        const style = { ...restProps.style, cursor: 'move' };

        let { className } = restProps;
        if (isOver) {
            if (restProps.index > dragingIndex) {
                className += ' drop-over-downward';
            }
            if (restProps.index < dragingIndex) {
                className += ' drop-over-upward';
            }
        }

        return connectDragSource(
            connectDropTarget(<tr {...restProps} className={className} style={style} />),
        );
    }
}

const rowSource = {
    beginDrag(props) {
        dragingIndex = props["data-row-key"];
        return {
            index: props["data-row-key"],
        };
    },
};
const rowTarget = {
    drop(props, monitor) {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props["data-row-key"];
        if (dragIndex === hoverIndex) {
            return;
        }
        props.moveRow(dragIndex, hoverIndex);
        monitor.getItem().index = hoverIndex;
    },
};
const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    }))(
        DragSource('row', rowSource, connect => ({
        connectDragSource: connect.dragSource(),
    }))(BodyRow),
);

const { Title } = Typography;
const {Option} = Select;
const { confirm } = Modal;
const FormItem = Form.Item;

const formItemLayout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 12 },
};

const defaultPagination = {
    pageSizeOptions: ['10', '20', '50', '70', '100', '200', '500'],
    showSizeChanger: true,
    size: 'small',
    position: 'top',
}

const styles = theme => ({
    button: {
        marginTop: "-12px",
    },
    input: {
        display: 'none',
    },
    list: {
        width: 500,
    },
});

class Types extends Component {
   
    constructor(props) {
        super(props);
        this.state = {
            name : '',
            thumbnail        : [],
            thumbnailsm     : [],
            visible         : false,
            type            : "new",
            id              : null,
            isdirect        : false,
            is360           : false,
            catalog_id      : null,
            direct_name     : '',
            direct_back     : null,
        };
        props.getTypes();
        props.getCatalogCategory();
    }
    components = {
        body: {
          row: DragableBodyRow,
        },
      };

    moveRow = (dragIndex, hoverIndex) => {
        const {typesData} = this.props; 
        dragIndex = typesData.findIndex( el => el.id === dragIndex);
        hoverIndex = typesData.findIndex( el => el.id === hoverIndex);
        if (hoverIndex < dragIndex){
            this.props.setSortOrder({id: typesData[dragIndex].id, sort_order: hoverIndex});        
            for (let rowIndex = hoverIndex; rowIndex < dragIndex; rowIndex++) {
                console.log(rowIndex, typesData[rowIndex].name);
                this.props.setSortOrder({id: typesData[rowIndex].id, sort_order: rowIndex + 1});        
                
            }
        }else{
            this.props.setSortOrder({id: typesData[dragIndex].id, sort_order: hoverIndex});
            for (let rowIndex = hoverIndex; rowIndex < dragIndex; rowIndex++) {
                console.log(rowIndex, typesData[rowIndex].name);
                this.props.setSortOrder({id: typesData[rowIndex].id, sort_order: rowIndex - 1});
                
            }
        }
        // this.props.setSortOrder({id: typesData[dragIndex].id, sort_order: typesData[hoverIndex].sort_order});
        // this.props.setSortOrder({id: typesData[hoverIndex].id, sort_order: typesData[dragIndex].sort_order});        
    };


    toggleDrawer = (type, record, open) => () => {
        if(type === "new") {
            this.setState({
                name            : '',
                type            : type,
                id              : null,
                thumbnail       : [],
                thumbnailsm     : [],
                isdirect        : false,
                is360           : false,
                catalog_id      : null,
                direct_name     : '',
                direct_back     : null,
            })
        }
        else if(type === "edit") {
            this.setState({
                id          : record.id,
                guid        : record.guid,
                name : record.name,
                type            : type,
                catalog_id      : record.catalog_id,
                thumbnail: [{
                    uid     : 1,
                    name    : record.thumbnail.split('/').pop(),
                    status  : 'done',
                    url     : record.thumbnail
                }],
                thumbnailsm: [{
                    uid     : 2,
                    name    : record.thumbnailsm.split('/').pop(),
                    status  : 'done',
                    url     : record.thumbnailsm
                }],
                isdirect    : record.isdirect !== 0 ? true : false,
                is360       : record.is360 !== 0 ? true : false,
            })
        }
        this.setState({
          visible: open,
        });
    };

    // canBeSubmitted()
    // {
    //     const {door_style_name, thumbnail, type} = this.state;
    //     return type==="new" ? (door_style_name.length > 0 && thumbnail.length > 0) : (door_style_name.length > 0);
    // }

    handleSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const {type, id} = this.state;
                this.setState({visible: false});
                console.log("values", values);
                if(type === "new") {
                    this.props.addTypes(values);
                }
                if(type === "edit" && id !== null) {
                    this.props.updateTypes(values, id, this.state.guid);
                }
            }
        });
    }

    handleDeleteEvent = (record) => {
            const {deleteTypes} = this.props;
            confirm({
                title: 'Do you want to delete this Item?',
                onOk() {
                    deleteTypes(record)
                },
                onCancel() {},
            });
    }

    normFile = e => {
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
    }

    moveAction = (type, record) => () => {
        const {typesData} = this.props;
        const index = typesData.findIndex( el => el.id === record.id);
        let swapIndex = -1; 
        if (type === "up" && index !== 0 && typesData[index-1].door_category_id === record.door_category_id) {
            swapIndex = index - 1;
        }
        else if (type === "down" && index !== typesData.length - 1 && typesData[index+1].door_category_id === record.door_category_id) {
            swapIndex = index + 1;
        }
        if(swapIndex !== -1) {
            this.props.setSortOrder({id: typesData[index].id, sort_order: typesData[swapIndex].sort_order});
            this.props.setSortOrder({id: typesData[swapIndex].id, sort_order: typesData[index].sort_order});
        }
    }


    render()
    {
        const { classes, typesData, isLoading, catalogCategory } = this.props;
        const { thumbnail, thumbnailsm, name, visible, type, isdirect, is360, catalog_id, direct_back, direct_name } = this.state;
        const { getFieldDecorator, setFieldsValue } = this.props.form;
        const uploadButton = (
            <div>
              <AddIcon />
              <div className="ant-upload-text">
                {type==="new" ? "Upload" : "Change & Upload"}
            </div>
            </div>
        );

        const catalogItems = [];
        catalogCategory.forEach(fElement => {
            catalogItems.push(<Option key={fElement.id} value={fElement.id}>{fElement.name}</Option>);
        })
 
        const columns = [
            {
                key: 'name',
                title: 'Name',
                dataIndex: 'name',
                width: 200,
            },
            {
                key: 'catalog_name',
                title: 'Catalog Name',
                dataIndex: 'catalog_id',
                width: 130,
                render: (text, record) => {
                    let result = "";
                    const index = catalogCategory.findIndex((el) => {
                        return el.id == record.catalog_id;
                    });
                    if(index != -1)
                    {
                        result = catalogCategory[index].name;
                    }
                    return (
                        <span>{result}</span>
                    );
                }
            },
            {
                key: 'thumbnail',
                title: 'Thumbnail',
                dataIndex: 'thumbnail',
                colSpan: 2,
                render: (text, record) => (
                    <Tooltip placement="topLeft" title={text}>
                        <span>{text}</span>
                    </Tooltip>
                )
            },
            {
                key: 'preview',
                dataIndex: 'thumbnail',
                colSpan: 0,
                width: 150,
                render: (text, record) => (
                    <Row className="list__thumbnail">
                        <img src={text} alt="" />
                    </Row>
                ),
            },
            {
                key: 'isdirect',
                title: 'Direct',
                dataIndex: 'isdirect',
                width: 200,
                render: (text, record) => (
                    <Chip
                        label={record.isdirect !== 0 ? "true" : "false"}
                        className={classes.button}
                        color={record.isdirect !== 0 ? "secondary" : "primary"}
                    />
                ),
            },
            {
                key: 'is360',
                title: 'For 360',
                dataIndex: 'is360',
                width: 200,
                render: (text, record) => (
                    <Chip
                        label={record.is360 !== 0 ? "true" : "false"}
                        className={classes.button}
                        color={record.is360 !== 0 ? "secondary" : "primary"}
                    />
                ),
            },
            {
                key: 'action',
                title: 'Action',
                dataIndex: 'id',
                width: 250,
                fixed: 'right',
                render: (text, record) => (
                    <Row>
                        <IconButton className={classes.button} aria-label="ArrowUp" onClick={this.moveAction("up", record)}>
                            <ArrowUpwardIcon />
                        </IconButton>
                        <IconButton className={classes.button} aria-label="ArrowDown" onClick={this.moveAction("down", record)}>
                            <ArrowDownwardIcon />
                        </IconButton>
                        <IconButton className={classes.button} aria-label="Delete" onClick={this.toggleDrawer("edit", record, true)}>
                            <EditIcon />
                        </IconButton>
                        <IconButton className={classes.button} aria-label="Edit" onClick={e => {e.preventDefault(); this.handleDeleteEvent(record);}}>
                            <DeleteIcon />
                        </IconButton>
                    </Row>
                ),
            },
        ];

        return (
            <Spin tip="Loading..." spinning={isLoading}>
                <FusePageCarded
                    classes={{
                        toolbar: "p-0",
                        header : "min-h-72 h-72 sm:h-136 sm:min-h-136"
                    }}
                    header={
                        <div className="flex flex-1 w-full items-center justify-between">
                            <div className="flex flex-col items-start max-w-full">
                                <div className="flex items-center max-w-full">
                                    <div className="flex flex-col min-w-0">
                                        <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                                            <Title level={4} className="text-16 sm:text-20 truncate">
                                            </Title>
                                        </FuseAnimate>
                                    </div>
                                </div>
                            </div>
                            <FuseAnimate animation="transition.slideRightIn" delay={300}>
                                <Button
                                    className="whitespace-no-wrap"
                                    variant="contained"
                                    onClick={this.toggleDrawer("new", null, true)}
                                >
                                    Add
                                </Button>
                            </FuseAnimate>
                        </div>
                    }
                    contentToolbar={
                        <Title level={4} style={{marginLeft: "25px"}} className="text-16 sm:text-20 truncate" classes={{root: "w-full h-64"}}>
                            {"Type"}
                        </Title>
                    }
                    content={
                        <div className="p-24">
                            <Row>
                                <Drawer
                                    anchor="right"
                                    open={visible}
                                    variant="temporary"
                                    onClose={this.toggleDrawer(null, null, false)}
                                    classes={{paper: classes.list}}
                                    onRendered={() => {
                                        var data = {
                                            name     : this.state.name,
                                            thumbnail           : this.state.thumbnail,
                                            thumbnailsm : this.state.thumbnailsm,
                                            catalog_id  : this.state.catalog_id,
                                        }
                                        setFieldsValue({...data})}}
                                >
                                    <div
                                        tabIndex={0}
                                        role="button"
                                    >
                                        <Title level={3} className="pt-16" style={{textAlign: "center"}}>
                                            { type === "new" ? "Add" : "Edit"} Type
                                        </Title>
                                        <Divider />
                                        <Form layout="vertical" style={{marginTop: "20px"}}>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="name"
                                                        {...formItemLayout}
                                                        label="Type Name"
                                                    >
                                                        {getFieldDecorator("name", {
                                                            initialValue: name,
                                                            rules: [ {
                                                                required: true, message: 'Enter Type Name.',
                                                            }],
                                                        })(
                                                            (<Input
                                                                placeholder="Enter Type Name"
                                                                onChange={(e)=> {
                                                                    this.setState({name: e.target.value});
                                                                }}
                                                            />)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="Thumbnail"
                                                        {...formItemLayout}
                                                        label="Thumbnail (Full Image)"
                                                    >
                                                        {getFieldDecorator("thumbnail", {
                                                            initialValue: thumbnail,
                                                            valuePropName: 'fileList',
                                                            rules: [{ required: true, message: 'Please select Thumbnail!' }],
                                                            getValueFromEvent: this.normFile,
                                                        })(
                                                            ( <Upload
                                                                listType="picture-card"
                                                                className="upload-list-inline"
                                                                onRemove={file => {
                                                                    this.setState({
                                                                        thumbnail: [],
                                                                    });
                                                                }}
                                                                beforeUpload={file => {
                                                                    this.setState({
                                                                        thumbnail: [file],
                                                                    });
                                                                    return false;
                                                                }}
                                                                >
                                                                {thumbnail.length > 0 ? null : uploadButton}
                                                            </Upload>)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="Thumbnailsm"
                                                        {...formItemLayout}
                                                        label="Thumbnailsm"
                                                    >
                                                        {getFieldDecorator("thumbnailsm", {
                                                            initialValue: thumbnailsm,
                                                            valuePropName: 'fileList',
                                                            rules: [{ required: true, message: 'Please select Thumbnailsm!' }],
                                                            getValueFromEvent: this.normFile,
                                                        })(
                                                            ( <Upload
                                                                listType="picture-card"
                                                                className="upload-list-inline"
                                                                onRemove={file => {
                                                                    this.setState({
                                                                        thumbnailsm: [],
                                                                    });
                                                                }}
                                                                beforeUpload={file => {
                                                                    this.setState({
                                                                        thumbnailsm: [file],
                                                                    });
                                                                    return false;
                                                                }}
                                                                >
                                                                {thumbnailsm.length > 0 ? null : uploadButton}
                                                            </Upload>)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="isdirect"
                                                        {...formItemLayout}
                                                        label="Direct"
                                                    >
                                                        {getFieldDecorator('isdirect', {
                                                        valuePropName: 'checked',
                                                        initialValue: isdirect,
                                                        })(<Checkbox disabled={(type == 'edit' && isdirect == false)}></Checkbox>)}
                                                    </FormItem>
                                                </Col>            
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="is360"
                                                        {...formItemLayout}
                                                        label="For 360"
                                                    >
                                                        {getFieldDecorator('is360', {
                                                        valuePropName: 'checked',
                                                        initialValue: is360,
                                                        })(<Checkbox></Checkbox>)}
                                                    </FormItem>
                                                </Col>            
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="catalog_id"
                                                        {...formItemLayout}
                                                        label="Catalog Name"
                                                    >
                                                        {getFieldDecorator("catalog_id", {
                                                            initialValue: catalog_id,
                                                            rules: [ {
                                                                required: true, message: 'Choose Catalog.',
                                                            }],
                                                        })(
                                                            (<Select
                                                                showSearch
                                                                dropdownStyle={{ zIndex: 10000, maxHeight: 400, overflow: 'auto' }}
                                                                placeholder="Please select your catalog!"
                                                                treeDefaultExpandAll
                                                                onSelect={(value) => { this.setState({ catalog_id: value}); }}
                                                            >
                                                                {catalogItems}
                                                            </Select>)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                        </Form>  
                                        <div
                                            style={{
                                                position: 'absolute',
                                                left: 0,
                                                bottom: 0,
                                                width: '100%',
                                                borderTop: '1px solid #e9e9e9',
                                                padding: '10px 16px',
                                                background: '#fff',
                                                textAlign: 'right',
                                            }}
                                        >
                                            <Button onClick={this.toggleDrawer(null, null, false)} style={{ marginRight: 8 }}>
                                                Cancel
                                            </Button>
                                            <Button 
                                                onClick={this.handleSubmit} 
                                                variant="contained" 
                                                color="primary" 
                                                // disabled={!this.canBeSubmitted()}
                                            >
                                                {type === "new" ? "ADD" : "UPDATE"}
                                            </Button>
                                        </div>
                                    </div>
                                </Drawer>
                                <Table 
                                    bordered
                                    rowKey="id"
                                    className="mtable"
                                    pagination={defaultPagination}
                                    columns={columns} 
                                    dataSource={typesData}
                                    scroll={{x: 1400, y: 500}}
                                    components={this.components}
                                    onRow={(record, index) => ({
                                        index,
                                        moveRow: this.moveRow,
                                    })}
                                />
                            </Row>
                        </div>
                    }
                />
            </Spin>
        )
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        getTypes: Actions.getTypes,
        addTypes: Actions.addTypes,
        deleteTypes: Actions.deleteTypes,
        updateTypes: Actions.updateTypes,
        showMessage     : appActions.showMessage,
        setSortOrder: Actions.setSortOrder,
        getCatalogCategory: Actions.getCatalogCategory,
    }, dispatch);
}

function mapStateToProps({types, fuse})
{   
    return {
        typesData: types.types.types,
        isLoading:fuse.loading.isLoading,
        catalogCategory: types.types.catalogCategory,
    }
}
const TypesComponent = Form.create()(DragDropContext(HTML5Backend)(Types));

export default withReducer('types', reducer)(withStyles(styles, {withTheme: true})(withRouter(connect(mapStateToProps,mapDispatchToProps)(TypesComponent))));