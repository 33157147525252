import Pcategories from './Pcategories';
import {authRoles} from 'app/auth';

export const PcategoriesConfig = {
    settings: {
        layout: {
        }
    },
    auth    : authRoles.designer,
    routes  : [
        {
            path     : '/pcategories',
            component: Pcategories
        }
    ]
};