import {authRoles} from 'app/auth';

const navigationConfig = [
    {
        'id'      : 'applications',
        'title'   : '',
        'type'    : 'group',
        'icon'    : 'apps',
        'url'     : '/applications',
        'children': [
            {
                'id'   : 'brand',
                'title': 'Brand',
                'type' : 'item',
                'icon' : 'style',
                'auth' : authRoles.designer,
                'url'  : '/brand'
            },
            {
                'id'   : 'catalog',
                'title': 'Catalog',
                'type' : 'item',
                'icon' : 'style',
                'auth' : authRoles.designer,
                'url'  : '/catalog'
            },
            {
                'id'   : 'types',
                'title': 'Types',
                'type' : 'item',
                'icon' : 'style',
                'auth' : authRoles.designer,
                'url'  : '/types'
            },
            {
                'id'   : 'shapes',
                'title': 'Shapes',
                'type' : 'item',
                'icon' : 'style',
                'auth' : authRoles.designer,
                'url'  : '/shapes'
            },
            {
                'id'   : 'main_back',
                'title': '3D Assets(Main Background)',
                'type' : 'item',
                'icon' : 'style',
                'auth' : authRoles.designer,
                'url'  : '/mainbackground'
            },
            // {
            //     'id'   : 'layer',
            //     'title': 'Layer Mask Image',
            //     'type' : 'item',
            //     'icon' : 'style',
            //     'auth' : authRoles.designer,
            //     'url'  : '/layer'
            // },
            {
                'id'   : 'categories',
                'title': 'Categories',
                'type' : 'item',
                'icon' : 'colorize',
                'auth' : authRoles.designer,
                'url'  : '/categories'
            },
            {
                'id'   : 'parent_categories',
                'title': 'ParentCategories',
                'type' : 'item',
                'icon' : 'colorize',
                'auth' : authRoles.designer,
                'url'  : '/pcategories'
            },
            {
                'id'   : 'category_hotspots',
                'title': 'Hotspots',
                'type' : 'item',
                'icon' : 'person',
                auth : authRoles.designer,
                'url'  : '/hotspots'
            }, 
            {
                'id'   : 'molding_shape',
                'title': 'Molding Shape',
                'type' : 'item',
                'icon' : 'colorize',
                'auth' : authRoles.designer,
                'url'  : '/molding/shape'
            },
            {
                'id'   : 'mcategories',
                'title': 'Molding Categories',
                'type' : 'item',
                'icon' : 'colorize',
                'auth' : authRoles.designer,
                'url'  : '/mcategories'
            },
            {
                'id'   : 'projectlog',
                'title': 'Project Log',
                'type' : 'item',
                'icon' : 'person',
                auth : authRoles.designer,
                'url'  : '/projectlog'
            },   
            {
                'id'   : 'emaillog',
                'title': 'Email Log',
                'type' : 'item',
                'icon' : 'person',
                auth : authRoles.designer,
                'url'  : '/emaillog'
            },
            {
                'id'   : 'user',
                'title': 'User',
                'type' : 'item',
                'icon' : 'person',
                auth : authRoles.superDesigner,
                'url'  : '/user'
            },
            {
                'id'   : 'dealer',
                'title': 'Dealer',
                'type' : 'item',
                'icon' : 'person',
                auth : authRoles.superDesigner,
                'url'  : '/dealer'
            },
            {
                'id'   : 'urlgenerator',
                'title': 'Dynamic URL',
                'type' : 'item',
                'icon' : 'colorize',
                auth : authRoles.superDesigner,
                'url'  : '/url/generator'
            },

            {
                'id'   : 'home',
                'title': 'Home',
                'type' : 'item',
                'icon' : 'home',
                auth : authRoles.dealer,
                'url'  : '/admin/home'
            },
            {
                'id'   : 'leads',
                'title': 'Leads',
                'type' : 'item',
                'icon' : 'person',
                auth : authRoles.dealer,
                'url'  : '/admin/leads'
            },
            {
                'id'   : 'content',
                'title': 'Content',
                'type' : 'item',
                'icon' : 'sort',
                auth : authRoles.dealer,
                'url'  : '/admin/content'
            },
            {
                'id'   : 'visualidentity',
                'title': 'Visual Identity',
                'type' : 'item',
                'icon' : 'create',
                auth : authRoles.dealer,
                'url'  : '/admin/visualidentity'
            },
            {
                'id'   : 'subscription',
                'title': 'Subscription',
                'type' : 'item',
                'icon' : 'pan_tool',
                auth : authRoles.dealer,
                'url'  : '/admin/subscription'
            },
        ]
    }
];

export default navigationConfig;
