import * as Actions from '../actions';

const initialState = {
    categories: [],
    mainbackgroundData: [],
    pcategories: [],
    catalog:[],
};

const categoriesReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.GET_CATEGORIES:
        {
            return {
                ...state,
                categories: action.categories,
            }   
        }
        case Actions.GET_MAIN_BACKGROUND: 
        {
            return {
                ...state,
                ...action,
            }
        }
        case Actions.GET_PCATEGORIES: 
        {
            return {
                ...state,
                ...action,
            }
        }
        case Actions.GET_CATALOG_DATA:
        {
            return {
                ...state,
                ...action,
            }   
        }
        default:
        {
            return state;
        }
    }
};

export default categoriesReducer;
