import Color from "./Color";
import { authRoles } from "app/auth";

export const ColorConfig = {
  settings: {
    layout: {}
  },
  auth: authRoles.designer,
  routes: [
    {
      path: "/color",
      component: Color
    }
  ]
};
